import React, { Component } from "react";

class Default extends Component {
  render() {
    return (
      <div className="col-xs-12">
        <div className="box box-primary">
          <div className="box-header with-border">
            <div className="row">
              <div className="col-xs-6">
                <h3 className="box-title-prmth">Anunţuri</h3>
              </div>
              <div className="col-xs-6 text-right">
                <div className="no-margin" style={{ paddingTop: "12px" }}></div>
              </div>
            </div>
          </div>
        </div>
        <h3>page not found</h3>
      </div>
    );
  }
}

export default Default;
