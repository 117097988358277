import React, { Component } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

class RangePrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.initialPrice,
      //initialPrice: this.props.initialPrice,
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };

    this.props.callback(this.state.value);
  }

  componentWillReceiveProps({ savedPrice, tip_anunt, tip_prop }) {
    this.setState({
      value: savedPrice,
      tip_anunt: tip_anunt,
      tip_prop: tip_prop,
    });
  }

  handleChange = (value) => {
    this.setState({ value });
    this.props.callback(value);
    //console.log(this.state.value.min, this.state.value.max);
  };

  render() {
    //const format = { __html: "m<sup>2</sup>" };
    /*
    const initialPrice = this.state.initialPrice;

    let min, max, step;
    if (initialPrice.min === 20000) {
      min = 10000;
      max = 400000;
      step = 5000;
    } else {
      min = 50;
      max = 5000;
      step = 50;
    }
    */

    const tip_anunt = this.state.tip_anunt;
    let min, max, step;
    if (tip_anunt === "vanzare") {
      min = 10000;
      max = 400000;
      step = 5000;
    } else {
      min = 50;
      max = 5000;
      step = 50;
    }

    return (
      <form style={{ margin: "0 auto", padding: "20px 15px 20px" }}>
        <InputRange
          minValue={min}
          maxValue={max}
          formatLabel={(value) => `${value}`}
          step={step}
          value={this.state.value}
          onChange={(value) => this.setState({ value })}
          onChangeComplete={this.handleChange}
        />
      </form>
    );
  }
}

export default RangePrice;
