import React, { Component } from "react";
import Modal from "react-modal";
import loginService from "./servicelogin";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  },
  overlay: { zIndex: 1000 }
};

class Technicians extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showUpdateModal: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      latitude: null,
      longitude: null,
      items: [],
      idToEdit: ""
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenUpdateModal = this.handleOpenUpdateModal.bind(this);
    this.handleCloseUpdateModal = this.handleCloseUpdateModal.bind(this);

    this.addTechnician = this.addTechnician.bind(this);
    this.loadTechnicians = this.loadTechnicians.bind(this);
    this.showTechnicians = this.showTechnicians.bind(this);
    this.clickEdit = this.clickEdit.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleOpenUpdateModal() {
    this.setState({ showUpdateModal: true });
  }

  handleCloseUpdateModal() {
    this.setState({ showUpdateModal: false });
  }

  addTechnician = async event => {
    event.preventDefault();
    const techObject = {
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      email: this.state.email,
      telephone: this.state.phoneNumber,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      services: ["type1", "type2", "type3"]
    };

    this.handleCloseModal();

    await loginService.addTechnician(techObject);

    window.location.reload();
  };

  componentDidMount() {
    this.loadTechnicians();
    //console.log(this.state.items);
  }

  loadTechnicians = async () => {
    //loginService.getTechnicians(data => console.log(data));
    const data = await loginService.getTechnicians();

    //console.error(data);

    this.setState({
      items: data
    });

    //console.error(this.state.items);
  };

  clickEdit = async id => {
    //console.error(id);
    await this.setState({ idToEdit: id });

    const technician = this.state.items.filter(tech => tech._id === id)[0];

    this.setState({
      firstName: technician.firstname,
      lastName: technician.lastname,
      email: technician.email,
      phoneNumber: technician.telephone,
      latitude: technician.latitude,
      longitude: technician.longitude
    });
    //console.error(this.state.idToEdit);
    this.handleOpenUpdateModal();
    //console.error(this.state.idToEdit);
  };

  clickDelete = async id => {
    //await this.setState({ idToEdit: id });
    await loginService.deleteTechnician(id);

    window.location.reload();
  };

  showTechnicians = () => {
    const technicians = this.state.items;

    return technicians.map(tech => (
      <div key={tech._id} className="panel panel-info">
        <div className="panel-heading">
          <i className="glyphicon glyphicon-user"></i>&nbsp;
          {tech.firstname} {tech.lastname}&nbsp;
          <button
            type="submit"
            onClick={() => {
              this.clickEdit(tech._id);
            }}
            //onClick={this.handleOpenUpdateModal(tech._id)}
            className="btn btn-primary btn-sm"
          >
            Editează
          </button>{" "}
          <button
            type="submit"
            onClick={() => {
              this.clickDelete(tech._id);
            }}
            className="btn btn-danger btn-sm"
          >
            Șterge
          </button>
        </div>
        <div className="panel-body">
          <i className="glyphicon glyphicon-envelope"></i>&nbsp;
          {tech.email}&nbsp;
          <i className="glyphicon glyphicon-phone"></i>&nbsp;
          {tech.telephone}
        </div>
      </div>
    ));
  };

  editTechnician = async event => {
    event.preventDefault();
    //console.error(this.state);
    const techObject = {
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      email: this.state.email,
      telephone: this.state.phoneNumber,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      services: ["type1", "type2", "type3"]
    };

    this.handleCloseUpdateModal();

    await loginService.updateTechnician(this.state.idToEdit, techObject);

    window.location.reload(true);
  };

  render() {
    //style={{ position: "relative", width: "50%" }}

    return (
      <div>
        {this.showTechnicians()}
        <button
          type="button"
          onClick={this.handleOpenModal}
          className="btn btn-primary"
        >
          Adaugă un nou tehnician
        </button>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="Adaugare tehnician"
          style={customStyles}
        >
          <form onSubmit={this.addTechnician}>
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                name="firstname"
                value={this.state.firstName}
                onChange={e => this.setState({ firstName: e.target.value })}
              />
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                value={this.state.lastName}
                onChange={e => this.setState({ lastName: e.target.value })}
              />
              <br />
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                name="email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
              />
              <label>Phone number</label>
              <input
                type="text"
                className="form-control"
                name="telephone"
                value={this.state.phoneNumber}
                onChange={e => this.setState({ phoneNumber: e.target.value })}
              />
              <br />
              <label>Location</label>
              <input
                type="text"
                className="form-control"
                name="latitude"
                value={this.state.latitude}
                onChange={e => this.setState({ latitude: e.target.value })}
              />
              <input
                type="text"
                className="form-control"
                name="longitude"
                value={this.state.longitude}
                onChange={e => this.setState({ longitude: e.target.value })}
              />
              <br />
              <button
                type="submit"
                //onClick={this.handleCloseModal}
                className="btn btn-primary"
              >
                Adaugă
              </button>{" "}
              <button
                type="submit"
                onClick={this.handleCloseModal}
                className="btn btn-primary"
              >
                Renunță
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={this.state.showUpdateModal}
          contentLabel="Editare tehnician"
          style={customStyles}
        >
          <form onSubmit={this.editTechnician}>
            <div className="form-group">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                name="firstname"
                //value={this.state.firstName}
                defaultValue={
                  this.state.idToEdit
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].firstname
                    : undefined
                }
                /*
                placeholder={
                  this.state.idToEdit
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].firstname
                    : undefined
                    */
                /*
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].firstname
                    : undefined
                    */
                //}
                onChange={e => {
                  console.error(
                    this.state.items.filter(
                      tech => tech._id === this.state.idToEdit
                    )[0].firstname
                  );
                  this.setState({ firstName: e.target.value });
                }}
              />
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                name="lastname"
                //value={this.state.lastName}
                defaultValue={
                  this.state.idToEdit
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].lastname
                    : undefined
                }
                onChange={e => {
                  this.setState({ lastName: e.target.value });
                }}
              />
              <br />
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                name="email"
                //value={this.state.email}
                defaultValue={
                  this.state.idToEdit
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].email
                    : undefined
                }
                onChange={e => {
                  this.setState({ email: e.target.value });
                }}
              />
              <label>Phone number</label>
              <input
                type="text"
                className="form-control"
                name="telephone"
                //value={this.state.phoneNumber}
                defaultValue={
                  this.state.idToEdit
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].telephone
                    : undefined
                }
                onChange={e => {
                  this.setState({ phoneNumber: e.target.value });
                }}
              />
              <br />
              <label>Location</label>
              <input
                type="text"
                className="form-control"
                name="latitude"
                //value={this.state.latitude}
                defaultValue={
                  this.state.idToEdit
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].latitude
                    : undefined
                }
                onChange={e => {
                  this.setState({ latitude: e.target.value });
                }}
              />
              <input
                type="text"
                className="form-control"
                name="longitude"
                //value={this.state.longitude}
                defaultValue={
                  this.state.idToEdit
                    ? this.state.items.filter(
                        tech => tech._id === this.state.idToEdit
                      )[0].longitude
                    : undefined
                }
                onChange={e => {
                  this.setState({ longitude: e.target.value });
                }}
              />
              <br />
              <button
                type="submit"
                //onClick={this.handleCloseModal}
                className="btn btn-primary"
              >
                Editează
              </button>{" "}
              <button
                type="submit"
                onClick={this.handleCloseUpdateModal}
                className="btn btn-primary"
              >
                Renunță
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

/*
function Technicians() {
    const { register, handleSubmit, watch, errors } = useForm();
    const onSubmit = data => console.log(data);

    //console.log(watch('example'));

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <label>First Name</label>
            <input name="firstname" ref={register({ required: true })} />

            <label>Last Name</label>
            <input name="lastname" ref={register({ required: true })} />

            <label>Email</label>
            <input name="email" ref={register({ required: true })} />

            <label>Phone number</label>
            <input name="telephone" ref={register({ required: true })} />

            <label>Location</label>
            <input name="latitude" ref={register({ required: true })} />
            <input name="longitude" ref={register({ required: true })} />

            <input type="submit" />

        </form>
    )
}
*/

export default Technicians;
