import React, { Component } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

class RangePriceFixed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.savedPrice,
      initialPrice: this.props.initialPrice,
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };
  }

  componentWillReceiveProps({ savedPrice, tip_anunt, tip_prop }) {
    this.setState({
      value: savedPrice,
      tip_anunt: tip_anunt,
      tip_prop: tip_prop,
    });
  }

  render() {
    const tip_anunt = this.state.tip_anunt;
    let min, max, step;
    if (tip_anunt === "vanzare") {
      min = 10000;
      max = 400000;
      step = 5000;
    } else {
      min = 50;
      max = 5000;
      step = 50;
    }

    return (
      <form style={{ margin: "0 auto", padding: "20px 15px 20px" }}>
        <InputRange
          minValue={min}
          maxValue={max}
          formatLabel={(value) => `${value}`}
          step={step}
          value={this.state.value}
          onChange={(value) =>
            console.log("Tried to change fixed range")
          }
          //onChangeComplete={this.handleChange}
        />
      </form>
    );
  }
}

export default RangePriceFixed;
