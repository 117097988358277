import React, { Component } from "react";
import { Pagination } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

class PaginationExamplePagination extends Component {
  constructor(props) {
    super(props);
    //page = this.props.page;
    this.state = {
      activePage: this.props.page,
      boundaryRange: 1,
      siblingRange: 1,
      showEllipsis: true,
      showFirstAndLastNav: true,
      showPreviousAndNextNav: true,
      totalPages: 1000
      //pageChanged: this.props.pageChanged,
      //filtersAppl: this.props.filtersAppl
    };
    //this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("Component updated");
    const page = prevState.activePage;
    //console.log(page);
    //console.log(this.props.page);

    //console.log(page !== this.props.page && this.props.page == 1);

    if (page !== this.props.page && this.props.page == 1) {
      this.setState({
        activePage: this.props.page
      });
    }
  }

  handlePaginationChange = (e, { activePage }) => {
    this.setState({ activePage: activePage });
    this.props.callbackPage(activePage);
  };

  render() {
    const {
      activePage,
      boundaryRange,
      siblingRange,
      showEllipsis,
      showFirstAndLastNav,
      showPreviousAndNextNav,
      totalPages
    } = this.state;
    return (
      <div>
        <Pagination
          activePage={activePage}
          boundaryRange={boundaryRange}
          onPageChange={this.handlePaginationChange}
          size="mini"
          siblingRange={siblingRange}
          totalPages={totalPages}
          ellipsisItem={showEllipsis ? undefined : null}
          firstItem={showFirstAndLastNav ? undefined : null}
          lastItem={showFirstAndLastNav ? undefined : null}
          prevItem={showPreviousAndNextNav ? undefined : null}
          nextItem={showPreviousAndNextNav ? undefined : null}
        />
      </div>
    );
  }
}

export default withRouter(PaginationExamplePagination);
