import React, { Component } from "react";
import Dropdown from "./Dropdown";
import Range from "./Range";
import RangePrice from "./RangePrice";

const nrCamere = [
  {
    label: "O cameră",
    value: "1",
  },
  {
    label: "2 camere",
    value: "2",
  },
  {
    label: "3 camere",
    value: "3",
  },
  {
    label: "4 camere",
    value: "4",
  },
  {
    label: "Mai multe",
    value: "5",
  },
];

const etaj = [
  {
    label: "Parter",
    value: "0",
  },
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
  {
    label: "7",
    value: "7",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "9",
    value: "9",
  },
  {
    label: "10",
    value: "10",
  },
  {
    label: "Peste",
    value: "11",
  },
  {
    label: "Mansardă",
    value: "99",
  },
];

class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfRooms: [],
      floor: [],
      usableSurface: {
        min: 10,
        max: 200,
      },
      price: this.props.initialPrice,
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };

    //console.error("Saved nr rooms", this.props.savedNrRooms);
  }

  componentWillReceiveProps({
    savedNrRooms,
    savedFloor,
    savedUsableSurface,
    savedPrice,
    tip_anunt,
    tip_prop,
  }) {
    //console.error("Saved props", savedNrRooms, savedFloor, savedUsableSurface, savedPrice);
    this.setState({
      numberOfRooms: savedNrRooms,
      floor: savedFloor,
      usableSurface: savedUsableSurface,
      price: savedPrice,
      tip_anunt: tip_anunt,
      tip_prop: tip_prop,
    });
  }

  callbackNrRooms = (values) => {
    this.setState({ numberOfRooms: values });
    this.props.callbackNrRooms(values);
  };

  callbackFloor = (values) => {
    this.setState({ floor: values });
    this.props.callbackFloor(values);
  };

  callbackUsableSurface = (value) => {
    this.setState({ usableSurface: value });
    this.props.callbackUsableSurface(value);
  };

  callbackPrice = (value) => {
    this.setState({ price: value });
    this.props.callbackPrice(value);
  };

  render() {
    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    let showFloor = true;
    if (tip_prop === "case" || tip_prop === "terenuri") {
      showFloor = false;
    }

    let showRooms = true;
    if (tip_prop === "terenuri") {
      showRooms = false;
    }

    return (
      <div className="flex-container">
        {showRooms ? (
          <div
            className="form-group"
            style={{ display: "inline-block", marginLeft: "1vw" }}
          >
            <label>Număr camere</label>
            <Dropdown
              options={nrCamere}
              callback={this.callbackNrRooms}
              savedNrRooms={this.state.numberOfRooms}
            />
          </div>
        ) : (
          <div
            style={{
              display: "inline-block",
              width: "0",
              margin: "0",
            }}
          ></div>
        )}

        {showFloor ? (
          <div
            className="form-group"
            style={{ display: "inline-block", marginLeft: "1vw" }}
          >
            <label>Etaj</label>
            <Dropdown
              options={etaj}
              callback={this.callbackFloor}
              savedFloor={this.state.floor}
            />
          </div>
        ) : (
          <div
            style={{
              display: "inline-block",
              width: "0",
              margin: "0",
            }}
          ></div>
        )}
        <br />
        <div
          className="form-group"
          style={{ marginLeft: "1vw", display: "inline-block" }}
        >
          <label>Suprafață utilă</label>
          <Range
            callback={this.callbackUsableSurface}
            savedUsableSurface={this.state.usableSurface}
          />
        </div>
        <div
          className="form-group"
          style={{ marginLeft: "1vw", display: "inline-block" }}
        >
          <label>Preț</label>
          <RangePrice
            callback={this.callbackPrice}
            savedPrice={this.state.price}
            initialPrice={this.state.initialPrice}
            tip_anunt={this.state.tip_anunt}
            tip_prop={this.state.tip_prop}
          />
        </div>
      </div>
      /*
      <div className="flex-container">
        <div
          className="form-group"
          style={{ width: "25vw", display: "inline-block", marginLeft: "1vw" }}
        >
          <label>Număr camere</label>
          <Dropdown options={nrCamere} callback={this.callbackNrRooms} />
        </div>
        <div
          className="form-group"
          style={{ width: "25vw", display: "inline-block", marginLeft: "1vw" }}
        >
          <label>Etaj</label>
          <Dropdown options={etaj} callback={this.callbackFloor} />
        </div>
        <br />
        <div
          className="form-group"
          style={{ width: "25vw", marginLeft: "1vw", display: "inline-block" }}
        >
          <label>Suprafață utilă</label>
          <Range callback={this.callbackUsableSurface} />
        </div>
        <div
          className="form-group"
          style={{ width: "25vw", marginLeft: "1vw", display: "inline-block" }}
        >
          <label>Preț</label>
          <RangePrice callback={this.callbackPrice} />
        </div>
      </div>
      */
    );
  }
}

export default DropdownMenu;
