import React, { Component } from "react";
import loginService from "./servicelogin";

import { Link } from "react-router-dom";

class Anunt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announce: this.props.detalii,
      isFavorite: false,
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
      fromFavorites: false,
    };

    this.addToFavorites = this.addToFavorites.bind(this);
    this.deleteFromFavorites = this.deleteFromFavorites.bind(this);
  }

  componentDidMount() {
    const userFavorites = this.state.announce.userFavorites;

    const loggedUserJSON = window.localStorage.getItem("loggedUser");
    let userId;

    if (loggedUserJSON) {
      //console.error("User ID", JSON.parse(loggedUserJSON).userid);

      userId = JSON.parse(loggedUserJSON).userid;

      this.setState({
        isFavorite: userFavorites.includes(userId),
      });
      //console.error("Is favorite", this.state.isFavorite);
    }

    if (this.props.favorite) {
      this.setState({
        fromFavorites: true,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.error("Next props", nextProps.tip);

    if (
      nextProps.tip_anunt !== this.state.tip_anunt ||
      nextProps.tip_prop !== this.state.tip_prop
    ) {
      this.setState({
        tip_anunt: nextProps.tip_anunt,
        tip_prop: nextProps.tip_prop,
      });
    }
  }

  addToFavorites = async (event) => {
    //event.preventDefault();

    const id = this.state.announce._id;

    const announceId = {
      announceId: id,
    };

    await loginService.addFavoriteAnnounce(announceId);

    this.setState({
      isFavorite: true,
    });
  };

  deleteFromFavorites = async (event) => {
    //event.preventDefault();

    const id = this.state.announce._id;

    const announceId = {
      announceId: id,
    };

    await loginService.deleteFavoriteAnnounce(announceId);

    this.setState({
      isFavorite: false,
    });
  };

  render() {
    const isFavorite = this.state.isFavorite;

    const starColor = isFavorite === true ? "yellow" : "white";

    let camere;

    const loggedUserJSON = window.localStorage.getItem("loggedUser");

    if (
      this.state.announce.characteristics &&
      this.state.announce.characteristics.numberOfRooms
    ) {
      if (this.state.announce.characteristics.numberOfRooms > 1) {
        camere = (
          <small>
            {this.state.announce.characteristics.numberOfRooms} &nbsp;
            camere
          </small>
        );
      } else {
        camere = (
          <small>
            {this.state.announce.characteristics.numberOfRooms} &nbsp;
            cameră
          </small>
        );
      }
    } else {
      camere = <small></small>;
    }

    return (
      <div className="box_announce box box-widget">
        <div className="box-body">
          <div
            id={this.state.announce._id}
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target={"#" + this.state.announce._id}
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target={"#" + this.state.announce._id}
                data-slide-to="1"
              ></li>
              <li
                data-target={"#" + this.state.announce._id}
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="item active">
                <img
                  src={this.state.announce.images[0]}
                  style={{ width: "100%", height: "40vh" }}
                />
              </div>
              <div className="item">
                <img
                  src={this.state.announce.images[1]}
                  style={{ width: "100%", height: "40vh" }}
                />
              </div>
              <div className="item">
                <img
                  src={this.state.announce.images[2]}
                  style={{ width: "100%", height: "40vh" }}
                />
              </div>

              <div className="carousel-caption">
                <p className="price">
                  <b>Preț: {this.state.announce.price.value}€</b>
                </p>
              </div>

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <div className="carousel-caption container-favorite">
                    <button
                      type="submit"
                      onClick={() => {
                        //console.error("Button clicked");
                        this.state.isFavorite === true
                          ? this.state.fromFavorites === true
                            ? this.props.deleteFavorite()
                            : this.deleteFromFavorites()
                          : this.addToFavorites();
                      }}
                    >
                      <i
                        className="fa fa-star fa-2x"
                        style={{ color: starColor }}
                      ></i>
                    </button>
                  </div>
                )}

              <a
                className="left carousel-control"
                href={"#" + this.state.announce._id}
                data-slide="prev"
              >
                <span className="glyphicon glyphicon-chevron-left"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control"
                href={"#" + this.state.announce._id}
                data-slide="next"
              >
                <span className="glyphicon glyphicon-chevron-right"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>

        <div className="box-footer">
          <h2 style={{ fontSize: "18px" }}>
            <Link
              to={`/${this.state.tip_anunt}/${this.state.tip_prop}/${this.state.announce._id}`}
              target="_blank"
            >
              <b>{this.state.announce.title}</b>
            </Link>
            <br />
            {camere}
            {/*
              <small>
                {props.detalii.characteristics.numberOfRooms}
                &nbsp;{" "}
                {props.detalii.characteristics.numberOfRooms > 1
                  ? "camere"
                  : "cameră"}
              </small>
              */}
            <br />
            <small>
              <i className="fa fa-map-marker"></i>{" "}
              {this.state.announce.zone.extended || undefined}
            </small>
            <br />
          </h2>
          {this.props.favorite !== undefined ? (
            //<Link to="/anunturi/favorite">
            <button
              type="submit"
              onClick={() => {
                this.props.deleteFavorite();
              }}
              className="btn btn-danger btn-sm"
            >
              Șterge din favorite
            </button>
          ) : (
            //</Link>
            <span></span>
          )}
        </div>
        <br />
      </div>
    );
  }
}

export default Anunt;
