import React, { Component } from "react";
import { baseUrl } from "../config";
import loginService from "./servicelogin";
//import axios from "axios";

import Afisare_Detalii from "./Afisare_Detalii";

class Detalii extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      error: null,
      isLoaded: false,
      announce: {},
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.state.id = id;

    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    let url;

    if (tip_prop === "apartamente") {
      if (tip_anunt === "vanzare") {
        url = "sale/";
      } else if (tip_anunt === "inchiriere") {
        url = "rent/";
      }
    } else if (tip_prop === "case") {
      if (tip_anunt === "vanzare") {
        url = "saleHouse/";
      } else if (tip_anunt === "inchiriere") {
        url = "rentHouse/";
      }
    } else if (tip_prop === "terenuri") {
      if (tip_anunt === "vanzare") {
        url = "saleLand/";
      } else if (tip_anunt === "inchiriere") {
        url = "rentLand/";
      }
    }

    //axios
    // .get(baseUrl + "app/getAnnounceById", id)
    //fetch(`${baseUrl}app/getAnnounceById/${id}`)
    fetch(baseUrl + `app/${url}/getAnnounceById/${id}`)
      .then((response) => response.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            id: id,
            announce: result,
          });
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, announce } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <Afisare_Detalii
            anunt={announce}
            tip_anunt={this.state.tip_anunt}
            tip_prop={this.state.tip_prop}
          />
        </div>
      );
    }
  }
}
export default Detalii;
