import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    const loggedUserJSON = window.localStorage.getItem("loggedUser");

    return (
      <header className="main-header">
        <a href="#" className="logo" style={{ textDecoration: "none" }}>
          <span className="logo-mini">
            <i className="fa fa-university"></i>
          </span>
          <span className="logo-lg">
            <i className="fa fa-home"></i>
            <b> Treadstone 2.0</b>
          </span>
        </a>
        <nav className="navbar navbar-static-top">
          <a
            href="#"
            className="sidebar-toggle"
            data-toggle="push-menu"
            role="button"
          >
            <span className="sr-only">Toggle navigation</span>
          </a>
          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                {!loggedUserJSON && (
                  <Link to="/login">
                    <i className="fa fa-user"></i>
                    <span>Login</span>
                  </Link>
                )}
                {loggedUserJSON && (
                  /*
                  <button
                    type="submit"
                    onClick={() => {
                      window.localStorage.clear();
                      
                      this.props.history.push({
                        pathname: "/",
                        state: { loggedIn: false }
                      });
                      
                      //<Link to="/">Logout</Link>;
                    }}
                  >
                    Logout
                  </button>
                  */

                  /*
                  <div>
                    <Link>
                      Hello,{" "}
                      {
                        JSON.parse(window.localStorage.getItem("loggedUser"))
                          .name
                      }
                      ! &nbsp;
                    </Link>
                    <Link to="/logout">
                      <i className="fa fa-user"></i>
                      <span>Logout</span>
                    </Link>
                  </div>
                  */

                  /*
                  <span>
                    Hello,{" "}
                    {JSON.parse(window.localStorage.getItem("loggedUser")).name}
                    ! &nbsp;
                    <Link to="/logout">
                      <i className="fa fa-user"></i>
                      <span>Logout</span>
                    </Link>
                  </span>
                  */

                  <Link to="/logout">
                    Hello,{" "}
                    {JSON.parse(window.localStorage.getItem("loggedUser")).username}
                    ! &nbsp;
                    <i className="fa fa-user"></i>
                    <span>Logout</span>
                  </Link>
                )}
                {/*
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-user"></i>
                  Login
                </a>
              */}
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
