import React, { Component } from "react";
import { Link } from "react-router-dom";
import Range from "./Range";
import RangePrice from "./RangePrice";
import { withRouter } from "react-router-dom";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueFromRange: null,
      valueFromRangePrice: null,
    };
  }

  render() {
    const loggedUserJSON = window.localStorage.getItem("loggedUser");

    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <ul className="sidebar-menu tree" data-widget="tree">
            <li className="header">MENIU</li>
            <li className="header">Apartamente</li>
            <li className="active treeview menu-open">
              <a href="#">
                <span>Apartamente de vânzare</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/vanzare/apartamente">
                    <i className="fa fa-list"></i>
                    <span>Anunţuri</span>
                  </Link>
                </li>
                {loggedUserJSON &&
                  JSON.parse(loggedUserJSON).features.includes(3) && (
                    <li>
                      <Link to="/vanzare/apartamente/harta">
                        <i className="fa fa-map-marker"></i>
                        <span>Hartă</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(0) && (
                    <li>
                      <Link to="/vanzare/apartamente/hartau">
                        <i className="fa fa-map-marker"></i>
                        <span>HartăU</span>
                      </Link>
                    </li>
                )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/vanzare/apartamente/listacautare">
                        <i className="fa fa-list"></i>
                        <span>Listele mele</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/vanzare/apartamente/favorite">
                        <i className="fa fa-star"></i>
                        <span>Anunțuri favorite</span>
                      </Link>
                    </li>
                  )}
              </ul>
            </li>

            <li className="treeview">
              <a href="#">
                <span>Apartamente de închiriat</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/inchiriere/apartamente">
                    <i className="fa fa-list"></i>
                    <span>Anunţuri</span>
                  </Link>
                </li>
                {loggedUserJSON &&
                  JSON.parse(loggedUserJSON).features.includes(3) && (
                    <li>
                      <Link to="/inchiriere/apartamente/harta">
                        <i className="fa fa-map-marker"></i>
                        <span>Hartă</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(0) && (
                    <li>
                      <Link to="/inchiriere/apartamente/hartau">
                        <i className="fa fa-map-marker"></i>
                        <span>HartăU</span>
                      </Link>
                    </li>
                )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/inchiriere/apartamente/listacautare">
                        <i className="fa fa-list"></i>
                        <span>Listele mele</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/inchiriere/apartamente/favorite">
                        <i className="fa fa-star"></i>
                        <span>Anunțuri favorite</span>
                      </Link>
                    </li>
                  )}
              </ul>
            </li>

            <li className="header">Case</li>
            <li className="treeview">
              <a href="#">
                <span>Case de vânzare</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/vanzare/case">
                    <i className="fa fa-list"></i>
                    <span>Anunţuri</span>
                  </Link>
                </li>
                {loggedUserJSON &&
                  JSON.parse(loggedUserJSON).features.includes(3) && (
                    <li>
                      <Link to="/vanzare/case/harta">
                        <i className="fa fa-map-marker"></i>
                        <span>Hartă</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(0) && (
                    <li>
                      <Link to="/vanzare/case/hartau">
                        <i className="fa fa-map-marker"></i>
                        <span>HartăU</span>
                      </Link>
                    </li>
                )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/vanzare/case/listacautare">
                        <i className="fa fa-list"></i>
                        <span>Listele mele</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/vanzare/case/favorite">
                        <i className="fa fa-star"></i>
                        <span>Anunțuri favorite</span>
                      </Link>
                    </li>
                  )}
              </ul>
            </li>

            <li className="treeview">
              <a href="#">
                <span>Case de închiriat</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/inchiriere/case">
                    <i className="fa fa-list"></i>
                    <span>Anunţuri</span>
                  </Link>
                </li>
                {loggedUserJSON &&
                  JSON.parse(loggedUserJSON).features.includes(3) && (
                    <li>
                      <Link to="/inchiriere/case/harta">
                        <i className="fa fa-map-marker"></i>
                        <span>Hartă</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(0) && (
                    <li>
                      <Link to="/inchiriere/case/hartau">
                        <i className="fa fa-map-marker"></i>
                        <span>HartăU</span>
                      </Link>
                    </li>
                )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/inchiriere/case/listacautare">
                        <i className="fa fa-list"></i>
                        <span>Listele mele</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/inchiriere/case/favorite">
                        <i className="fa fa-star"></i>
                        <span>Anunțuri favorite</span>
                      </Link>
                    </li>
                  )}
              </ul>
            </li>

            <li className="header">Terenuri</li>
            <li className="treeview">
              <a href="#">
                <span>Terenuri de vânzare</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/vanzare/terenuri">
                    <i className="fa fa-list"></i>
                    <span>Anunţuri</span>
                  </Link>
                </li>
                {loggedUserJSON &&
                  JSON.parse(loggedUserJSON).features.includes(3) && (
                    <li>
                      <Link to="/vanzare/terenuri/harta">
                        <i className="fa fa-map-marker"></i>
                        <span>Hartă</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(0) && (
                    <li>
                      <Link to="/vanzare/terenuri/hartau">
                        <i className="fa fa-map-marker"></i>
                        <span>HartăU</span>
                      </Link>
                    </li>
                )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/vanzare/terenuri/listacautare">
                        <i className="fa fa-list"></i>
                        <span>Listele mele</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/vanzare/terenuri/favorite">
                        <i className="fa fa-star"></i>
                        <span>Anunțuri favorite</span>
                      </Link>
                    </li>
                  )}
              </ul>
            </li>

            <li className="treeview">
              <a href="#">
                <span>Terenuri de închiriat</span>
                <span className="pull-right-container">
                  <i className="fa fa-angle-left pull-right"></i>
                </span>
              </a>
              <ul className="treeview-menu">
                <li>
                  <Link to="/inchiriere/terenuri">
                    <i className="fa fa-list"></i>
                    <span>Anunţuri</span>
                  </Link>
                </li>
                {loggedUserJSON &&
                  JSON.parse(loggedUserJSON).features.includes(3) && (
                    <li>
                      <Link to="/inchiriere/terenuri/harta">
                        <i className="fa fa-map-marker"></i>
                        <span>Hartă</span>
                      </Link>
                    </li>
                  )}
                  {loggedUserJSON &&
                  JSON.parse(loggedUserJSON).features.includes(0) && (
                      <li>
                          <Link to="/inchiriere/terenuri/hartau">
                              <i className="fa fa-map-marker"></i>
                              <span>HartăU</span>
                          </Link>
                      </li>
                  )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/inchiriere/terenuri/listacautare">
                        <i className="fa fa-list"></i>
                        <span>Listele mele</span>
                      </Link>
                    </li>
                  )}
                {loggedUserJSON &&
                  (JSON.parse(loggedUserJSON).features.includes(4) ||
                    JSON.parse(loggedUserJSON).features.includes(
                      0
                    )) && (
                    <li>
                      <Link to="/inchiriere/terenuri/favorite">
                        <i className="fa fa-star"></i>
                        <span>Anunțuri favorite</span>
                      </Link>
                    </li>
                  )}
              </ul>
            </li>

            {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                <li className="treeview">
                  <a href="#">
                    <span>Servicii</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-left pull-right"></i>
                    </span>
                  </a>
                  <ul className="treeview-menu">
                    <li>
                      <Link to="/tehnicieni">
                        <i className="fa fa-list"></i>
                        <span>Tehnicieni</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hartatehnicieni">
                        <i className="fa fa-list"></i>
                        <span>Harta tehnicieni</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
          </ul>
        </section>
      </aside>
    );
  }
}

export default withRouter(Sidebar);
