import React, { Component } from "react";
import loginService from "./servicelogin";
import { Link } from "react-router-dom";
import Anunt from "./Anunt";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

class Anunturi_Favorite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favorites: [],
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };

    this.showFavorites = this.showFavorites.bind(this);
    this.deleteFavorite = this.deleteFavorite.bind(this);
  }

  componentDidMount() {
    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    if (tip_prop === "apartamente") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("sale/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rent/");
      }
    } else if (tip_prop === "case") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleHouse/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentHouse/");
      }
    } else if (tip_prop === "terenuri") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleLand/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentLand/");
      }
    }

    this.showFavorites();
  }

  componentWillReceiveProps(nextProps) {
    //console.error("Next props", nextProps.tip);
    if (
      nextProps.tip_anunt !== this.state.tip_anunt ||
      nextProps.tip_prop !== this.state.tip_prop
    ) {
      const new_tip_anunt = nextProps.tip_anunt;
      const new_tip_prop = nextProps.tip_prop;

      if (new_tip_prop === "apartamente") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("sale/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rent/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        }
      } else if (new_tip_prop === "case") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleHouse/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentHouse/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        }
      } else if (new_tip_prop === "terenuri") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleLand/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentLand/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        }
      }
    }

    this.showFavorites();
  }

  showFavorites = async () => {
    const data = await loginService.getFavoriteAnnounce();

    //console.error("Anunturi favorite", data.favorites);

    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    if (tip_prop === "apartamente") {
      if (tip_anunt === "vanzare") {
        if (data.favorites.length > 0) {
          this.setState({
            favorites: data.favorites,
          });
        } else {
          this.setState({
            favorites: [],
          });
        }
      } else if (tip_anunt === "inchiriere") {
        if (data.favoritesRent.length > 0) {
          this.setState({
            favorites: data.favoritesRent,
          });
        } else {
          this.setState({
            favorites: [],
          });
        }
      }
    } else if (tip_prop === "case") {
      if (tip_anunt === "vanzare") {
        if (data.saleHouseFavorites.length > 0) {
          this.setState({
            favorites: data.saleHouseFavorites,
          });
        } else {
          this.setState({
            favorites: [],
          });
        }
      } else if (tip_anunt === "inchiriere") {
        if (data.rentHouseFavorites.length > 0) {
          this.setState({
            favorites: data.rentHouseFavorites,
          });
        } else {
          this.setState({
            favorites: [],
          });
        }
      }
    } else if (tip_prop === "terenuri") {
      if (tip_anunt === "vanzare") {
        if (data.saleHouseFavorites.length > 0) {
          this.setState({
            favorites: data.saleLandFavorites,
          });
        } else {
          this.setState({
            favorites: [],
          });
        }
      } else if (tip_anunt === "inchiriere") {
        if (data.rentHouseFavorites.length > 0) {
          this.setState({
            favorites: data.rentLandFavorites,
          });
        } else {
          this.setState({
            favorites: [],
          });
        }
      }
    }
  };

  deleteFavorite = async (id) => {
    //console.log("Delete");

    const announceId = {
      announceId: id,
    };

    try {
      const response = await loginService.deleteFavoriteAnnounce(
        announceId
      );

      //console.error("Delete response", response);

      if (response.status === 200) {
        const oldFavorites = this.state.favorites;
        this.setState({
          favorites: oldFavorites.filter(
            (favorite) => favorite._id !== id
          ),
        });

        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () =>
            toastr.success(
              `Anunțul a fost șters cu succes din favorite`
            ),
          300
        );
      } else {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () =>
            toastr.error(`Anunțul nu a putut fi șters din favorite`),
          300
        );
      }
    } catch (err) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(
        () =>
          toastr.error(`Anunțul nu a putut fi șters din favorite`),
        300
      );
    }

    //window.location.reload();
  };

  render() {
    const favorites = this.state.favorites;

    if (favorites.length > 0) {
      return (
        <div>
          <div className="box box-primary">
            <div className="box-header with-border">
              <div className="col-xs-12">
                <h3 className="box-title-prmth">Anunţuri favorite</h3>
              </div>
            </div>
            <div className="box-body">
              <div className="flex-container">
                {favorites.map((announce) => (
                  <Anunt
                    detalii={announce}
                    key={announce._id}
                    favorite="yes"
                    deleteFavorite={() =>
                      this.deleteFavorite(announce._id)
                    }
                    tip_anunt={this.state.tip_anunt}
                    tip_prop={this.state.tip_prop}
                  />
                ))}
                {/*
                {favorites.map(announce => (
                  <div>
                    <Anunt detalii={announce} key={announce._id} />
                    <div key={announce._id}>{announce._id}</div>
                    <Link to="/anunturi/favorite">
                      <button
                        type="submit"
                        onClick={() => {
                          this.deleteFavorite(announce._id);
                        }}
                        className="btn btn-danger btn-sm"
                      >
                        Șterge din listă
                      </button>
                    </Link>
                  </div>
                ))}
                      */}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Nu aveți anunțuri salvate la favorite.</div>;
    }
  }
}

export default Anunturi_Favorite;
