import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="pull-right hidden-xs">
          <b>Version 2.0</b>
        </div>
        <strong>
          Copyright &copy; 2017-{new Date().getFullYear()}
          <a href="https://www.treadstone.ro/"> Treadstone </a>
        </strong>
        All rights reserved.
      </footer>
    );
  }
}

export default Footer;
