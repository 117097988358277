import React, { Component } from "react";
import loginService from "./servicelogin";
import { Link } from "react-router-dom";
import DropdownMenuFixed from "./DropdownMenuFixed";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

class DynamicList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createdAt: "",
      updatedAt: "",
      listId: "",
      filters: {},
      /*
      price: {
        min: 20000,
        max: 100000,
      },
      */
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };

    this.showDynamicList = this.showDynamicList.bind(this);
    this.deleteDynamicList = this.deleteDynamicList.bind(this);
  }

  componentDidMount() {
    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    //let url = "";

    if (tip_prop === "apartamente") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("sale/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rent/");
      }
    } else if (tip_prop === "case") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleHouse/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentHouse/");
      }
    } else if (tip_prop === "terenuri") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleLand/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentLand/");
      }
    }

    this.showDynamicList();
  }

  componentWillReceiveProps(nextProps) {
    //console.error("Next props", nextProps.tip);
    //console.error(nextProps.tip !== this.state.tip);

    if (
      nextProps.tip_anunt !== this.state.tip_anunt ||
      nextProps.tip_prop !== this.state.tip_prop
    ) {
      const new_tip_anunt = nextProps.tip_anunt;
      const new_tip_prop = nextProps.tip_prop;

      if (new_tip_prop === "apartamente") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("sale/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rent/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        }
      } else if (new_tip_prop === "case") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleHouse/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentHouse/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        }
      } else if (new_tip_prop === "terenuri") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleLand/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentLand/");

          this.setState({
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
          });
        }
      }
    }

    this.showDynamicList();
  }

  showDynamicList = async () => {
    const data = await loginService.getDynamicList();

    console.error("Lista dinamica", data);

    //console.error("Lista salvata", data[0].dynamicListId);

    if (data[0] !== undefined) {
      this.setState({
        createdAt: data[0].createdAt,
        updatedAt: data[0].updatedAt,
        listId: data[0].dynamicListId,
        filters: { ...data[0].query },
      });
    } else {
      this.setState({
        createdAt: "",
        updatedAt: "",
        listId: "",
        filters: {},
      });
    }

    //console.error(this.state);
  };

  deleteDynamicList = async (id) => {
    const listId = this.state.listId;
    const sendId = {
      dynamicListId: listId,
    };

    try {
      const response = await loginService.deleteDynamicList(sendId);

      //console.error(response);

      if (response.status === 200) {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () => toastr.success(`Lista a fost ștearsă cu succes`),
          300
        );
      } else {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () => toastr.error(`Lista nu a putut fi ștearsă`),
          300
        );
      }
    } catch (err) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(
        () => toastr.error(`Lista nu a putut fi ștearsă`),
        300
      );
    }
  };

  render() {
    const date = this.state.createdAt;
    const dateCreated = new Date(date);
    //console.error("dateCreated", date);
    //console.error("dateCreated", date.toUTCString());
    const showDateCreated = dateCreated.toUTCString();

    const dateUp = this.state.updatedAt;
    const dateUpdated = new Date(dateUp);
    const showDateUpdated = dateUpdated.toUTCString();

    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    let initialPrice;
    if (tip_anunt === "vanzare") {
      initialPrice = {
        min: 20000,
        max: 100000,
      };
    } else if (tip_anunt === "inchiriere") {
      initialPrice = {
        min: 100,
        max: 2000,
      };
    }

    //const showFilters = JSON.stringify(this.state.filters);

    /*
    const filters = this.state.filters;
    const numberOfRooms = filters.numberOfRooms;
    const floor = filters.floor;
    const usableSurface = filters.usableSurface;
    const price = filters.price;
    const textSearch = filter.textSearch;
    */

    const listId = this.state.listId;

    if (date !== "") {
      return (
        <div className="panel panel-info">
          <div className="panel-heading">
            <i className="glyphicon glyphicon-list"></i>&nbsp; Lista
            salvată&nbsp;
            <div>
              <i>creată la {showDateCreated}</i>
            </div>
            {/*
            <div>
              <i>actualizată la {showDateUpdated}</i>
            </div>
            */}
          </div>
          <div className="panel-body">
            <i className="glyphicon glyphicon-search"></i>&nbsp;
            Filtre&nbsp;
            {/*<div>{showFilters}</div>*/}
            {this.state.filters.textSearch !== undefined ? (
              <input
                type="text"
                value={this.state.filters.textSearch}
                className="form-control"
                //onChange={this.handleChange}
                placeholder="Caută..."
              />
            ) : (
              <span></span>
            )}
            <DropdownMenuFixed
              savedNrRooms={this.state.filters.numberOfRooms}
              savedFloor={this.state.filters.floor}
              savedUsableSurface={this.state.filters.usableSurface}
              savedPrice={this.state.filters.price}
              initialPrice={initialPrice}
              tip_anunt={this.state.tip_anunt}
              tip_prop={this.state.tip_prop}
            />
            <Link
              to={{
                pathname: `/${tip_anunt}/${tip_prop}`,
                state: {
                  fromSavedList: this.state.filters,
                  //tip: tip,
                },
              }}
            >
              <button
                type="submit"
                className="btn btn-success btn-sm"
              >
                Caută după parametrii salvați
              </button>
            </Link>{" "}
            <Link to={{ pathname: `/${tip_anunt}/${tip_prop}` }}>
              <button
                type="submit"
                onClick={() => {
                  this.deleteDynamicList(listId);
                }}
                className="btn btn-danger btn-sm"
              >
                Șterge
              </button>
            </Link>
          </div>
        </div>
      );
    } else {
      return <div>Nu aveți nicio listă de căutare salvată.</div>;
    }
  }
}

export default DynamicList;
