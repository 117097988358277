import React, {Component} from "react";
import Modal from "react-modal";
import loginService from "./servicelogin";
import L from "leaflet";
import axios from "axios";
import {baseUrl} from "../config";
import "../leaflet_awesome_number/src/leaflet_awesome_number_markers"
import "../leaflet_awesome_number/src/leaflet_awesome_number_markers.css"
import "leaflet.markercluster";
import {
    imobiliarero1,
    imobiliarero2,
    imobiliarero3,
    imobiliarero4,
    olxro1,
    olxro2,
    olxro3,
    olxro4,
    homezzro1,
    homezzro2,
    homezzro3,
    homezzro4,
    questionmark,
    water,
    technician,
    electric,
    default1,
    default2,
    default3,
    default4,
} from "../icons_leaf";
import DropdownMenu from "./DropdownMenu";
import _ from "underscore";
import {
    GeoSearchControl,
    OpenStreetMapProvider,
    EsriProvider,
} from "leaflet-geosearch";
import "../../node_modules/leaflet-geosearch/assets/css/leaflet.css";
import "../customleaflet.css";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
    },
    overlay: {zIndex: 1000}
};

class TechniciansMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                lat: 44.439663,
                lon: 26.096306,
            },
        };
    }

    componentDidMount() {

        if (!navigator.geolocation) {
            console.log('Geolocation is not supported by your browser');
        } else {
            //status.textContent = 'Locating…';
            navigator.geolocation.getCurrentPosition(
                (position => {
                    this.setState(prevState => (
                        {
                            filters:
                                {
                                    ...prevState.filter,
                                    lat: parseFloat(position.coords.latitude - 1),
                                    lon: parseFloat(position.coords.longitude)
                                }
                        }));
                    console.log(this.state);
                }), (error) => console.log("Error getting position"));
        }

        //const { lat, lon } = this.state.filters;


        const provider = new OpenStreetMapProvider({
            params: {countrycodes: ["ro", "br"]},
        });
        //const provider = new EsriProvider();

        const searchControl = new GeoSearchControl({
            provider: provider,
            //style: 'bar',
            showMarker: false,
            showPopup: false,
            autoClose: true,
            keepResult: true,
            searchLabel: "Introduceti adresa",
            position: "topleft",
        });

        this.map = L.map("map", {
            center: [this.state.filters.lat, this.state.filters.lon],
            zoom: 16,
            layers: [
                L.tileLayer(
                    "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ),
            ],
        });

        this.map.addControl(searchControl);

        searchControl.getContainer().onclick = (e) => {
            e.stopPropagation();
        };

        this.map.on("geosearch/showlocation", (e) => {
            // CLICK IN SEARCHBAR
            const lat = e.location.y;
            const lon = e.location.x;

            //console.log("Loading announces from ", lat, lon);

            const prevLat = this.state.filters.lat;
            const prevLon = this.state.filters.lon;

            //console.log(lat, lon, prevLat, prevLon);

            const dist = Math.sqrt(
                Math.pow(lat - prevLat, 2) + Math.pow(lon - prevLon, 2)
            );

            //console.log(dist);

            if (dist >= 0.015) {
                //console.log("Setting new state");
                this.setState((prevState) => ({
                    filters: {
                        ...prevState.filters,
                        lat: parseFloat(lat),
                        lon: parseFloat(lon),
                    },
                }));
            }
        });

        //const searchComponent = (props) => <ReactLeafletSearch position="topleft" />;
        //this.map.addHandler(searchComponent)
        //console.log(map);

        // this.marker = L.marker(this.props.markerPosition).addTo(this.map);
        this.extraMarkers = L.markerClusterGroup({
            spiderfyOnMaxZoom: false,
            disableClusteringAtZoom: 1,
        }).addTo(this.map);

        //ADAUGARE TEHNICIENI
        axios
            .get(baseUrl + "api/technicians")
            .then((res) =>
                res.data.map((t) =>
                    L.marker([t.latitude, t.longitude], {
                        icon:
                            t.services[0] === "type1"
                                ? electric
                                : t.services[0] === "type2"
                                ? water
                                : technician,
                    })
                        .bindPopup(
                            `<b>Nume: </b>${t.lastname}<br> <b>Numar de telefon: </b> ${t.telephone}<br> <b>Email: </b> ${t.email}`
                        )
                        .addTo(this.extraMarkers)
                )
            )
            .catch((e) => console.log(e));
        // [44.439663, 26.096306] CENTRU
        L.marker(
            [
                44.40322757785135 + Math.random() / 10,
                26.036306 + Math.random() / 10,
            ],
            {icon: electric}
        )
            .bindPopup(
                "<b>Nume: </b> Marian Andrei<br> <b>Email: </b> marian.andrei@gmail.com<br> <b>Numar de telefon: </b> 0788 123 456"
            )
            .addTo(this.extraMarkers);

        this.markers = L.markerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: true,
            zoomToBoundsOnClick: true,
            maxClusterRadius: function (zoom) {
                if (zoom === 18) return 0;
                return 80;
            },
        }).addTo(this.map);
        // this.layer = L.layerGroup().addTo(this.map);
        //this.updateMarkers(this.props.markersData);
        const filters = this.state.filters;
        //this.loadMarkers(filters);
        //this.loadMarkers(44.439663, 26.096306);

        this.map.on("click", (e) => {
            const lat = e.latlng.lat;
            const lon = e.latlng.lng;

            const prevLat = this.state.filters.lat;
            const prevLon = this.state.filters.lon;

            //console.log(lat, lon, prevLat, prevLon);

            const dist = Math.sqrt(
                Math.pow(lat - prevLat, 2) + Math.pow(lon - prevLon, 2)
            );

            //console.log(dist);

            if (dist >= 0.015) {
                this.setState((prevState) => ({
                    filters: {
                        ...prevState.filters,
                        lat: parseFloat(lat),
                        lon: parseFloat(lon),
                    },
                }));
            }
        });

        //console.log(this.state.items);
    }


    render() {

        return (
            <div> TEST
                <div
                    id="map"
                    style={{height: "80vh", width: "100vw", zIndex: "0"}}
                ></div>
            </div>

        );
    }
}


export default TechniciansMap;
