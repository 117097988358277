import React, { Component } from "react";
import Content from "./Content";

class Logout extends Component {
  render() {
    //console.log(window.localStorage.getItem("loggedUser"));

    const loggedUser = window.localStorage.getItem("loggedUser");
    const currentTime = new Date().getTime();
    const accepted = window.localStorage.getItem("accepted");

    if (loggedUser || accepted === currentTime) {
      window.localStorage.clear();
    }

    this.props.history.push({
      pathname: "/",
      state: { loggedIn: false }
    });

    return <div></div>;
  }
}

/*
const Logout = () => {
  console.log(window.localStorage.getItem("loggedUser"));

  const loggedUser = window.localStorage.getItem("loggedUser");

  if (loggedUser) {
    window.localStorage.clear();
  }

  return (
    <div>
      <Content />
    </div>
  );
};
*/

export default Logout;
