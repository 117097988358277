import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Lista_Anunturi from "./Lista_Anunturi";
import Detalii from "./Detalii";
import Harta from "./Harta";
import HartaU from "./HartaU";
import Default from "./Default";
import Login from "./Login";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Technicians from "./Technicians";
import loginService from "./servicelogin";
import DynamicList from "./DynamicList";
import Anunturi_Favorite from "./Anunturi_Favorite";
import TechniciansMap from "./TechniciansMap";

class Content extends Component {
  /*
  componentDidMount() {
    const loggedUserJSON = window.localStorage.getItem("loggedUser");
    console.log("User:", loggedUserJSON);
    console.log(
      "Features:",
      loggedUserJSON ? JSON.parse(loggedUserJSON).features : null
    );

    {
      loggedUserJSON &&
        loginService.setToken(JSON.parse(loggedUserJSON).token);
    }

    const expirationDuration = 1000 * 60; // 1 minute

    const prevAccepted = window.localStorage.getItem("accepted");
    const currentTime = new Date().getTime();

    const notAccepted = prevAccepted == undefined;
    const prevAcceptedExpired = prevAccepted != undefined && currentTime - prevAccepted > expirationDuration;
    if (notAccepted || prevAcceptedExpired) {
      //alert("Disclaimer: ...");
      window.localStorage.setItem("accepted", currentTime);
      //window.localStorage.clear();

      this.props.history.push({
        pathname: "/logout"
      });
    }
      */

  /*
  this.interval = setInterval(() => {
    const loggedUser = window.localStorage.getItem("loggedUser");
    const currentTime = new Date().getTime();
    const accepted = window.localStorage.getItem("accepted");

    if (loggedUser || accepted === currentTime) {
      window.localStorage.clear();

      this.props.history.push({
        pathname: "/logout"
      });
    }
  }, 15000);
  */

  render() {
    //const loggedUserJSON = window.localStorage.getItem("loggedUser");
    //console.log(this.props.location.state);

    /*
    console.log(window.localStorage.getItem("loggedUser"));

    const loggedUser = window.localStorage.getItem("loggedUser");

    if (loggedUser) {
      window.localStorage.clear();
    }
    */

    const loggedUserJSON = window.localStorage.getItem("loggedUser");
    //console.log("User:", loggedUserJSON);
    /*
    console.log(
      "Features:",
      loggedUserJSON ? JSON.parse(loggedUserJSON).features : null
    );
    */

    {
      loggedUserJSON &&
        loginService.setToken(JSON.parse(loggedUserJSON).token);
    }

    const expirationDuration = 1000 * 60 * 60 * 12; // 12 hours
    //const expirationDuration = 1000 * 60; // 1 minute

    const prevAccepted = window.localStorage.getItem("accepted");
    const currentTime = new Date().getTime();

    const notAccepted = prevAccepted == undefined;
    const prevAcceptedExpired =
      prevAccepted != undefined &&
      currentTime - prevAccepted > expirationDuration;
    if (notAccepted || prevAcceptedExpired) {
      //alert("Disclaimer: ...");
      window.localStorage.setItem("accepted", currentTime);
      //window.localStorage.clear();

      this.props.history.push({
        pathname: "/logout",
      });
      /*
      this.props.history.push({
        pathname: "/",
        state: { loggedIn: false }
      });
      */
    }

    const interval = setInterval(() => {
      const loggedUserJSON = window.localStorage.getItem(
        "loggedUser"
      );
      const prevAccepted = window.localStorage.getItem("accepted");
      const currentTime = new Date().getTime();

      const notAccepted = prevAccepted == undefined;
      const prevAcceptedExpired =
        prevAccepted != undefined &&
        currentTime - prevAccepted > expirationDuration;
      if (loggedUserJSON && (notAccepted || prevAcceptedExpired)) {
        //alert("Disclaimer: ...");
        window.localStorage.setItem("accepted", currentTime);
        //window.localStorage.clear();

        this.props.history.push({
          pathname: "/logout",
        });
      }
      /*
        const loggedUser = window.localStorage.getItem("loggedUser");
        const currentTime = new Date().getTime();
        const accepted = window.localStorage.getItem("accepted");
  
        console.log("Interval:", loggedUser, currentTime, accepted);
  
        if (currentTime === accepted) {
          window.localStorage.clear();
  
          this.props.history.push({
            pathname: "/logout"
          });
        }
        */
    }, 15000);

    return (
      <div>
        <Header />
        <Sidebar />

        <div className="content-wrapper" style={{ minHeight: 588 }}>
          <section className="content">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Lista_Anunturi
                    tip_anunt={"vanzare"}
                    tip_prop={"apartamente"}
                    {...props}
                  />
                )}
              />
              {/*
              <Route
                exact
                path="/vanzare/apartamente"
                component={Lista_Anunturi}
              />
              */}

              <Route
                exact
                path="/vanzare/apartamente"
                render={(props) => (
                  <Lista_Anunturi
                    tip_anunt={"vanzare"}
                    tip_prop={"apartamente"}
                    {...props}
                  />
                )}
              />

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/vanzare/apartamente/favorite"
                    render={(props) => (
                      <Anunturi_Favorite
                        tip_anunt={"vanzare"}
                        tip_prop={"apartamente"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(3) && (
                  <Route
                    exact
                    path="/vanzare/apartamente/harta"
                    render={(props) => (
                      <Harta
                        tip_anunt={"vanzare"}
                        tip_prop={"apartamente"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                      exact
                      path="/vanzare/apartamente/hartau"
                      render={(props) => (
                          <HartaU
                              tip_anunt={"vanzare"}
                              tip_prop={"apartamente"}
                              {...props}
                          />
                      )}
                  />
              )}

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/vanzare/apartamente/listacautare"
                    render={(props) => (
                      <DynamicList
                        tip_anunt={"vanzare"}
                        tip_prop={"apartamente"}
                        {...props}
                      />
                    )}
                  />
                )}

              <Route
                exact
                path="/vanzare/apartamente/:id"
                render={(props) => (
                  <Detalii
                    tip_anunt={"vanzare"}
                    tip_prop={"apartamente"}
                    {...props}
                  />
                )}
              />

              {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                    exact
                    path="/tehnicieni"
                    component={Technicians}
                  />
                )}

              {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                      exact
                      path="/hartatehnicieni"
                      component={TechniciansMap}
                  />
              )}

              {loggedUserJSON && (
                <Route
                  exact
                  path="/vanzare/apartamente/listacautare/:listId"
                  render={(props) => (
                    <Lista_Anunturi
                      tip_anunt={"vanzare"}
                      tip_prop={"apartamente"}
                      {...props}
                    />
                  )}
                />
              )}

              <Route
                exact
                path="/inchiriere/apartamente"
                render={(props) => (
                  <Lista_Anunturi
                    tip_anunt={"inchiriere"}
                    tip_prop={"apartamente"}
                    {...props}
                  />
                )}
              />

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/inchiriere/apartamente/favorite"
                    render={(props) => (
                      <Anunturi_Favorite
                        tip_anunt={"inchiriere"}
                        tip_prop={"apartamente"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(3) && (
                  <Route
                    exact
                    path="/inchiriere/apartamente/harta"
                    render={(props) => (
                      <Harta
                        tip_anunt={"inchiriere"}
                        tip_prop={"apartamente"}
                        {...props}
                      />
                    )}
                  />
                )}
              {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                      exact
                      path="/inchiriere/apartamente/hartau"
                      render={(props) => (
                          <HartaU
                              tip_anunt={"inchiriere"}
                              tip_prop={"apartamente"}
                              {...props}
                          />
                      )}
                  />
              )}

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/inchiriere/apartamente/listacautare"
                    render={(props) => (
                      <DynamicList
                        tip_anunt={"inchiriere"}
                        tip_prop={"apartamente"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON && (
                <Route
                  exact
                  path="/inchiriere/apartamente/listacautare/:listId"
                  render={(props) => (
                    <Lista_Anunturi
                      tip_anunt={"inchiriere"}
                      tip_prop={"apartamente"}
                      {...props}
                    />
                  )}
                />
              )}

              <Route
                exact
                path="/inchiriere/apartamente/:id"
                render={(props) => (
                  <Detalii
                    tip_anunt={"inchiriere"}
                    tip_prop={"apartamente"}
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path="/vanzare/case"
                render={(props) => (
                  <Lista_Anunturi
                    tip_anunt={"vanzare"}
                    tip_prop={"case"}
                    {...props}
                  />
                )}
              />

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/vanzare/case/favorite"
                    render={(props) => (
                      <Anunturi_Favorite
                        tip_anunt={"vanzare"}
                        tip_prop={"case"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
            JSON.parse(loggedUserJSON).features.includes(3) && (
                <Route
                    exact
                    path="/vanzare/case/harta"
                    render={(props) => (
                        <Harta
                            tip_anunt={"vanzare"}
                            tip_prop={"case"}
                            {...props}
                        />
                    )}
                />
            )}

              {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                      exact
                      path="/vanzare/case/hartau"
                      render={(props) => (
                          <HartaU
                              tip_anunt={"vanzare"}
                              tip_prop={"case"}
                              {...props}
                          />
                      )}
                  />
              )}

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/vanzare/case/listacautare"
                    render={(props) => (
                      <DynamicList
                        tip_anunt={"vanzare"}
                        tip_prop={"case"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON && (
                <Route
                  exact
                  path="/vanzare/case/listacautare/:listId"
                  render={(props) => (
                    <Lista_Anunturi
                      tip_anunt={"vanzare"}
                      tip_prop={"case"}
                      {...props}
                    />
                  )}
                />
              )}

              <Route
                exact
                path="/vanzare/case/:id"
                render={(props) => (
                  <Detalii
                    tip_anunt={"vanzare"}
                    tip_prop={"case"}
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path="/inchiriere/case"
                render={(props) => (
                  <Lista_Anunturi
                    tip_anunt={"inchiriere"}
                    tip_prop={"case"}
                    {...props}
                  />
                )}
              />

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/inchiriere/case/favorite"
                    render={(props) => (
                      <Anunturi_Favorite
                        tip_anunt={"inchiriere"}
                        tip_prop={"case"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(3) && (
                  <Route
                    exact
                    path="/inchiriere/case/harta"
                    render={(props) => (
                      <Harta
                        tip_anunt={"inchiriere"}
                        tip_prop={"case"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                      exact
                      path="/inchiriere/case/hartau"
                      render={(props) => (
                          <HartaU
                              tip_anunt={"inchiriere"}
                              tip_prop={"case"}
                              {...props}
                          />
                      )}
                  />
              )}

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/inchiriere/case/listacautare"
                    render={(props) => (
                      <DynamicList
                        tip_anunt={"inchiriere"}
                        tip_prop={"case"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON && (
                <Route
                  exact
                  path="/inchiriere/case/listacautare/:listId"
                  render={(props) => (
                    <Lista_Anunturi
                      tip_anunt={"inchiriere"}
                      tip_prop={"case"}
                      {...props}
                    />
                  )}
                />
              )}

              <Route
                exact
                path="/inchiriere/case/:id"
                render={(props) => (
                  <Detalii
                    tip_anunt={"inchiriere"}
                    tip_prop={"case"}
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path="/vanzare/terenuri"
                render={(props) => (
                  <Lista_Anunturi
                    tip_anunt={"vanzare"}
                    tip_prop={"terenuri"}
                    {...props}
                  />
                )}
              />

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/vanzare/terenuri/favorite"
                    render={(props) => (
                      <Anunturi_Favorite
                        tip_anunt={"vanzare"}
                        tip_prop={"terenuri"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(3) && (
                  <Route
                    exact
                    path="/vanzare/terenuri/harta"
                    render={(props) => (
                      <Harta
                        tip_anunt={"vanzare"}
                        tip_prop={"terenuri"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                      exact
                      path="/vanzare/terenuri/hartau"
                      render={(props) => (
                          <HartaU
                              tip_anunt={"vanzare"}
                              tip_prop={"terenuri"}
                              {...props}
                          />
                      )}
                  />
              )}

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/vanzare/terenuri/listacautare"
                    render={(props) => (
                      <DynamicList
                        tip_anunt={"vanzare"}
                        tip_prop={"terenuri"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON && (
                <Route
                  exact
                  path="/vanzare/terenuri/listacautare/:listId"
                  render={(props) => (
                    <Lista_Anunturi
                      tip_anunt={"vanzare"}
                      tip_prop={"terenuri"}
                      {...props}
                    />
                  )}
                />
              )}

              <Route
                exact
                path="/vanzare/terenuri/:id"
                render={(props) => (
                  <Detalii
                    tip_anunt={"vanzare"}
                    tip_prop={"terenuri"}
                    {...props}
                  />
                )}
              />

              <Route
                exact
                path="/inchiriere/terenuri"
                render={(props) => (
                  <Lista_Anunturi
                    tip_anunt={"inchiriere"}
                    tip_prop={"terenuri"}
                    {...props}
                  />
                )}
              />

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/inchiriere/terenuri/favorite"
                    render={(props) => (
                      <Anunturi_Favorite
                        tip_anunt={"inchiriere"}
                        tip_prop={"terenuri"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(3) && (
                  <Route
                    exact
                    path="/inchiriere/terenuri/harta"
                    render={(props) => (
                      <Harta
                        tip_anunt={"inchiriere"}
                        tip_prop={"terenuri"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON &&
              JSON.parse(loggedUserJSON).features.includes(0) && (
                  <Route
                      exact
                      path="/inchiriere/terenuri/hartau"
                      render={(props) => (
                          <HartaU
                              tip_anunt={"inchiriere"}
                              tip_prop={"terenuri"}
                              {...props}
                          />
                      )}
                  />
              )}

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <Route
                    exact
                    path="/inchiriere/terenuri/listacautare"
                    render={(props) => (
                      <DynamicList
                        tip_anunt={"inchiriere"}
                        tip_prop={"terenuri"}
                        {...props}
                      />
                    )}
                  />
                )}

              {loggedUserJSON && (
                <Route
                  exact
                  path="/inchiriere/terenuri/listacautare/:listId"
                  render={(props) => (
                    <Lista_Anunturi
                      tip_anunt={"inchiriere"}
                      tip_prop={"terenuri"}
                      {...props}
                    />
                  )}
                />
              )}

              <Route
                exact
                path="/inchiriere/terenuri/:id"
                render={(props) => (
                  <Detalii
                    tip_anunt={"inchiriere"}
                    tip_prop={"terenuri"}
                    {...props}
                  />
                )}
              />

              <Route component={Default} />
            </Switch>
          </section>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Content;
