import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

class DropdownFixed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options,
      selectedOptions: [],
    };

    //console.error("Saved nr rooms", this.props.savedNrRooms);
  }

  componentDidMount() {
    if (this.props.savedNrRooms !== undefined) {
      const numberOfRooms = this.props.savedNrRooms;
      this.setState({
        selectedOptions: numberOfRooms.map((number) => {
          if (number === 1) {
            return {
              label: "O cameră",
              value: "1",
            };
          }
          if (number < 5) {
            return {
              label: `${number} camere`,
              value: `${number}`,
            };
          }
          return {
            label: "Mai multe",
            value: "5",
          };
        }),
      });
    }
    if (this.props.savedFloor !== undefined) {
      const floor = this.props.savedFloor;
      this.setState({
        selectedOptions: floor.map((number) => {
          if (number === 0) {
            return {
              label: "Parter",
              value: "0",
            };
          }

          if (number === 11) {
            return {
              label: "Peste",
              value: "11",
            };
          }
          if (number === 99) {
            return {
              label: "Mansardă",
              value: "99",
            };
          }
          return {
            label: `${number}`,
            value: `${number}`,
          };
        }),
      });
    }
  }

  componentWillReceiveProps({ savedNrRooms, savedFloor }) {
    /*
    this.setState({
      selectedOptions: savedNrRooms
    })
    */
    //console.error("Saved nr rooms", savedNrRooms);

    if (savedNrRooms !== undefined) {
      /*
      this.setState({
        selectedOptions: savedNrRooms
      });  
      */
      this.setState({
        selectedOptions: savedNrRooms.map((number) => {
          if (number === 1) {
            return {
              label: "O cameră",
              value: "1",
            };
          }
          if (number < 5) {
            return {
              label: `${number} camere`,
              value: `${number}`,
            };
          }
          return {
            label: "Mai multe",
            value: "5",
          };
        }),
      });
    }

    if (savedFloor !== undefined) {
      /*
      this.setState({
        selectedOptions: savedNrRooms
      });  
      */
      this.setState({
        selectedOptions: savedFloor.map((number) => {
          if (number === 0) {
            return {
              label: "Parter",
              value: "0",
            };
          }

          if (number === 11) {
            return {
              label: "Peste",
              value: "11",
            };
          }
          if (number === 99) {
            return {
              label: "Mansardă",
              value: "99",
            };
          }
          return {
            label: `${number}`,
            value: `${number}`,
          };
        }),
      });
    }
  }

  render() {
    const { selectedOptions } = this.state;

    return (
      <form>
        <Select
          value={selectedOptions}
          options={this.state.options}
          placeholder={"Selectează"}
          components={makeAnimated()}
          isMulti
        />
        {/*{selectedOptions.map(item => console.log(item.value))}*/}
      </form>
    );
  }
}

export default DropdownFixed;
