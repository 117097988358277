import L from "leaflet";

import im0 from "./icons/imobiliarero0.png";
import im1 from "./icons/imobiliarero1.png";
import im2 from "./icons/imobiliarero2.png";
import im3 from "./icons/imobiliarero3.png";
import im4 from "./icons/imobiliarero4.png";
import ol0 from "./icons/olxro0.png";
import ol1 from "./icons/olxro1.png";
import ol2 from "./icons/olxro2.png";
import ol3 from "./icons/olxro3.png";
import ol4 from "./icons/olxro4.png";
import hzz1 from "./icons/homezzro1.png";
import hzz2 from "./icons/homezzro2.png";
import hzz3 from "./icons/homezzro3.png";
import hzz4 from "./icons/homezzro4.png";
import qm from "./icons/questionmark.png";
import wt from "./icons/water.png";
import tech from "./icons/technician.jpg";
import el from "./icons/electric.png";
import def0 from "./icons/default0.png"
import def1 from "./icons/default1.png"
import def2 from "./icons/default2.png"
import def3 from "./icons/default3.png"
import def4 from "./icons/default4.png"

const isize = 20;

const imobiliarero1 = L.icon({
  iconUrl: im1,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const imobiliarero2 = L.icon({
  iconUrl: im2,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const imobiliarero3 = L.icon({
  iconUrl: im3,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const imobiliarero4 = L.icon({
  iconUrl: im4,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const olxro1 = L.icon({
  iconUrl: ol1,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const olxro2 = L.icon({
  iconUrl: ol2,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const olxro3 = L.icon({
  iconUrl: ol3,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const olxro4 = L.icon({
  iconUrl: ol4,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const questionmark = L.icon({
  iconUrl: qm,
  iconSize: [isize, isize * 2], // size of the icon
  iconAnchor: [isize / 2, isize], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const water = L.icon({
  iconUrl: wt,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const technician = L.icon({
  iconUrl: tech,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const electric = L.icon({
  iconUrl: el,
  iconSize: [isize, isize], // size of the icon
  iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
  popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const homezzro1 = L.icon({
    iconUrl: hzz1,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const homezzro2 = L.icon({
    iconUrl: hzz2,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});
const homezzro3 = L.icon({
    iconUrl: hzz3,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const homezzro4 = L.icon({
    iconUrl: hzz4,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const default1 = L.icon({
    iconUrl: def1,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const default2 = L.icon({
    iconUrl: def2,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const default3 = L.icon({
    iconUrl: def3,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

const default4 = L.icon({
    iconUrl: def4,
    iconSize: [isize, isize], // size of the icon
    iconAnchor: [isize / 2, isize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
});

export {
  imobiliarero1,
  imobiliarero2,
  imobiliarero3,
  imobiliarero4,
  olxro1,
  olxro2,
  olxro3,
  olxro4,
  questionmark,
  water,
  technician,
  electric,
  homezzro1,
  homezzro2,
  homezzro3,
  homezzro4,
    default1,
    default2,
    default3,
    default4
};
