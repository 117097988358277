import React, { Component } from "react";
import axios from "axios";
import _ from "underscore";

import Anunt from "./Anunt";
import PaginationExamplePagination from "./Pagination";
import DropdownMenu from "./DropdownMenu";
import loginService from "./servicelogin";
import { Link } from "react-router-dom";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { baseUrl } from "../config";

class Lista_Anunturi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      items: [],
      isLoaded: false,
      error: null,
      search: "",
      filters: {
        numberOfRooms: [],
        floor: [],
        usableSurface: {
          min: 10,
          max: 200,
        },
        price: {
          min: 20000,
          max: 100000,
        },
        site: null,
        textSearch: null,
      },
      pageChanged: false,
      filtersAppl: false,
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };

    this.saveSearch = this.saveSearch.bind(this);
    this.showSearch = this.showSearch.bind(this);
    this.addDynamicList = this.addDynamicList.bind(this);
    //this.getDynamicList = this.getDynamicList.bind(this);
    this.deleteFavorite = this.deleteFavorite.bind(this);
  }

  callbackNrRooms = (values) => {
    this.setState((prevState) => ({
      page: 1,
      filters: {
        ...prevState.filters,
        numberOfRooms: values
          ? values.map((item) => parseInt(item.value))
          : [],
      },
    }));
  };

  callbackFloor = (values) => {
    this.setState((prevState) => ({
      page: 1,
      filters: {
        ...prevState.filters,
        floor: values
          ? values.map((item) => parseInt(item.value))
          : [],
      },
    }));
  };

  callbackUsableSurface = (value) => {
    this.setState((prevState) => ({
      page: 1,
      filters: {
        ...prevState.filters,
        usableSurface: value,
      },
    }));
  };

  callbackPrice = (value) => {
    this.setState((prevState) => ({
      page: 1,
      filters: {
        ...prevState.filters,
        price: value,
      },
    }));
  };

  callbackPage = (value) => {
    this.setState({
      page: value,
    });
    //console.log(this.state.page);
  };

  callbackFilters = (value) => {
    this.setState({
      filtersAppl: value,
    });
  };

  handleChange = (event) => {
    this.setState({ search: event.target.value });
  };

  handleSearch = (event) => {
    event.preventDefault();

    const search = this.state.search;
    this.setState((prevState) => ({
      page: 1,
      filters: {
        ...prevState.filters,
        textSearch: search,
      },
    }));
  };

  componentDidMount() {
    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    //let url = "";

    if (tip_prop === "apartamente") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("sale/");
        //url += "sale/";

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 20000,
              max: 100000,
            },
          },
        }));
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rent/");
        //url += "rent/";

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 100,
              max: 2000,
            },
          },
        }));
      }
    } else if (tip_prop === "case") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleHouse/");
        //url += "sale/";

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 20000,
              max: 100000,
            },
          },
        }));
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentHouse/");
        //url += "rent/";

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 100,
              max: 2000,
            },
          },
        }));
      }
    } else if (tip_prop === "terenuri") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleLand/");
        //url += "sale/";

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 20000,
              max: 1000000,
            },
          },
        }));
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentLand/");
        //url += "rent/";

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 100,
              max: 2000,
            },
          },
        }));
      }
    }

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.fromSavedList !== undefined
    ) {
      const { fromSavedList } = this.props.location.state;
      //console.error("Saved filters", fromSavedList);
      if (fromSavedList.textSearch !== null) {
        this.setState({
          filters: { ...fromSavedList },
          search: fromSavedList.textSearch,
        });
      } else {
        this.setState({
          filters: { ...fromSavedList },
          //search: null,
        });
      }

      //console.error("Search change", this.state.filters.textSearch);

      /*
      if (this.state.filters.textSearch !== null) {
        this.setState({
          
        });
      }
      */

      window.history.pushState(null, "/anunturi");
    }

    //console.error("List id", this.props.match.params.listId);

    if (this.props.match.params.listId !== undefined) {
      const id = this.props.match.params.listId;
      //console.error("List id", id);

      this.loadDynamicList(id);

      /*
      const data = this.loadDynamicList(id);

      this.setState({
        filters: { ...data[0].query }
      });
      */
    }

    //console.log("Component mounted");
    //console.log(this.props.match.params);
    //console.log(this.props.match.params.page);
    //console.log(this.props.match.params.page);

    const filters = this.state.filters;
    //console.log(filters);

    this.loadFilters(filters);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    console.error(
      "Next props",
      nextProps.tip_anunt,
      nextProps.tip_prop
    );

    if (
      nextProps.tip_anunt !== prevState.tip_anunt ||
      nextProps.tip_prop !== prevState.tip_prop
    ) {
      const new_tip_anunt = nextProps.tip_anunt;
      const new_tip_prop = nextProps.tip_prop;

      if (new_tip_prop === "apartamente") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("sale/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 20000,
                max: 100000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rent/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 100,
                max: 2000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        }
      } else if (new_tip_prop === "case") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleHouse/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 20000,
                max: 100000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentHouse/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 100,
                max: 2000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        }
      } else if (new_tip_prop === "terenuri") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleLand/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 2000,
              },
              price: {
                min: 10000,
                max: 1000000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentLand/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 2000,
              },
              price: {
                min: 100,
                max: 2000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        }
      } else return null;
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   console.error("Next props", nextProps.tip);

  //   if (nextProps.tip !== this.state.tip) {
  //     const newTip = nextProps.tip;
  //     if (newTip === "vanzare") {
  //       loginService.setUrl("sale/");

  //       console.log(loginService.getUrl());

  //       this.setState({
  //         tip: newTip,
  //         filters: {
  //           numberOfRooms: [],
  //           floor: [],
  //           usableSurface: {
  //             min: 10,
  //             max: 200,
  //           },
  //           price: {
  //             min: 20000,
  //             max: 100000,
  //           },
  //           site: null,
  //           textSearch: null,
  //         },
  //         page: 1,
  //         search: "",
  //       });
  //     } else if (newTip === "inchiriere") {
  //       loginService.setUrl("rent/");

  //       console.log(loginService.getUrl());

  //       this.setState({
  //         tip: newTip,
  //         filters: {
  //           numberOfRooms: [],
  //           floor: [],
  //           usableSurface: {
  //             min: 10,
  //             max: 200,
  //           },
  //           price: {
  //             min: 100,
  //             max: 2000,
  //           },
  //           site: null,
  //           textSearch: null,
  //         },
  //         page: 1,
  //         search: "",
  //       });
  //     }

  //     //console.error(this.state);

  //     const filters = this.state.filters;

  //     this.loadFilters(filters);
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    console.log("Component updated");

    /*
    const newTip = this.props.tip;

    if (newTip !== prevProps.tip) {
      this.setState({
        tip: newTip,
      });
    }
    */

    const filters = this.state.filters;
    const prevFilters = prevState.filters;

    //console.log(filters);
    //console.log(this.state.page);
    //console.log(prevState);
    //console.log(prevFilters);

    //console.log(!_.isEqual(filters, prevFilters));
    //console.log(this.state.page);
    //console.log(prevState.page);

    if (
      !_.isEqual(filters, prevFilters) ||
      //&& this.state.filtersAppl == false
      this.state.page !== prevState.page ||
      this.state.tip_anunt !== prevState.tip_anunt ||
      this.state.tip_prop !== prevState.tip_prop
    ) {
      /*
      if (
        !_.isEqual(filters, prevFilters) &&
        this.state.page == prevState.page
      ) {*/
      //this.setState({ page: 1, pageChanged: false, filtersAppl: true });
      this.loadFilters(filters);
    }
  }

  loadFilters = async (filters) => {
    //console.log(filters);

    const filtersSend = { ...filters };
    filtersSend.page = this.state.page;

    console.log("Load filters");

    const data = await loginService.getAnnounces(filtersSend);

    console.log(data);

    //console.log(data);
    this.setState({
      items: data,
    });
  };

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  saveSearch = () => {
    document.cookie = `filters=${JSON.stringify(this.state.filters)}`;

    //console.log(JSON.parse(this.getCookie("filters")));
  };

  showSearch = () => {
    const savedFilters = JSON.parse(this.getCookie("filters"));

    this.setState({
      filters: { ...savedFilters },
    });
  };

  addDynamicList = async (event) => {
    //event.preventDefault();
    const filters = this.state.filters;
    const query = {
      queryFilters: filters,
    };

    try {
      const response = await loginService.addDynamicList(query);

      if (response.status === 200) {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () => toastr.success(`Lista a fost salvată cu succes`),
          300
        );
      } else {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () => toastr.error(`Lista nu a putut fi salvată`),
          300
        );
      }
    } catch (err) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(
        () => toastr.error(`Lista nu a putut fi salvată`),
        300
      );
    }
  };

  /*
  getDynamicList = async event => {
    const data = await loginService.getDynamicList();

    console.error("Lista salvata", data[0]);
  };
  */

  loadDynamicList = async (id) => {
    const listId = {
      dynamicListId: id,
    };

    const data = await loginService.getListById(listId);
    const savedFilters = data[0].query;

    if (savedFilters.textSearch !== null) {
      this.setState({
        filters: { ...savedFilters },
        search: savedFilters.textSearch,
      });
    } else {
      this.setState({
        filters: { ...savedFilters },
      });
    }

    /*
    this.setState({
      filters: { ...data[0].query }
    });
    */

    //return data;
  };

  deleteFavorite = async (id) => {
    //console.log("Delete");

    const announceId = {
      announceId: id,
    };

    try {
      const response = await loginService.deleteFavoriteAnnounce(
        announceId
      );

      //console.error("Delete response", response);

      if (response.status === 200) {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () =>
            toastr.success(
              `Anunțul a fost șters cu succes din favorite`
            ),
          300
        );
      } else {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () =>
            toastr.error(`Anunțul nu a putut fi șters din favorite`),
          300
        );
      }
    } catch (err) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(
        () =>
          toastr.error(`Anunțul nu a putut fi șters din favorite`),
        300
      );
    }
  };

  render() {
    //const { error, isLoaded, items } = this.state;
    const { items } = this.state;
    /*
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {*/

    const loggedUserJSON = window.localStorage.getItem("loggedUser");
    //console.log("User:", loggedUserJSON);

    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    let initialPrice;

    if (tip_anunt === "vanzare") {
      initialPrice = {
        min: 20000,
        max: 100000,
      };
    } else if (tip_anunt === "inchiriere") {
      initialPrice = {
        min: 100,
        max: 2000,
      };
    }

    return (
      <div>
        <div className="box box-primary">
          <div className="box-header with-border">
            <div className="col-xs-12">
              <h3 className="box-title-prmth">Anunţuri</h3>

              <div
                id="searchBox"
                style={{
                  backgroundColor: "white",
                  width: "48%",
                }}
              >
                <form
                  className="sidebar-form"
                  onSubmit={this.handleSearch}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      value={this.state.search}
                      className="form-control"
                      onChange={this.handleChange}
                      placeholder="Caută..."
                    />
                    <span className="input-group-btn">
                      <button
                        type="submit"
                        name="search"
                        id="search-btn"
                        className="btn btn-flat"
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>

              {loggedUserJSON &&
                JSON.parse(loggedUserJSON).features.includes(2) && (
                  <DropdownMenu
                    callbackNrRooms={this.callbackNrRooms}
                    savedNrRooms={this.state.filters.numberOfRooms}
                    callbackFloor={this.callbackFloor}
                    savedFloor={this.state.filters.floor}
                    callbackUsableSurface={this.callbackUsableSurface}
                    savedUsableSurface={
                      this.state.filters.usableSurface
                    }
                    callbackPrice={this.callbackPrice}
                    savedPrice={this.state.filters.price}
                    initialPrice={initialPrice}
                    tip_anunt={this.state.tip_anunt}
                    tip_prop={this.state.tip_prop}
                  />
                )}

              {loggedUserJSON &&
                (JSON.parse(loggedUserJSON).features.includes(4) ||
                  JSON.parse(loggedUserJSON).features.includes(
                    0
                  )) && (
                  <div>
                    <div>
                      <button
                        type="submit"
                        onClick={() => {
                          this.saveSearch();
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        Salvează parametrii căutare
                      </button>{" "}
                      <button
                        type="submit"
                        onClick={() => {
                          this.showSearch();
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        Caută după parametrii salvați
                      </button>{" "}
                      <button
                        type="submit"
                        onClick={() => {
                          this.addDynamicList();
                        }}
                        className="btn btn-success btn-sm"
                      >
                        Salvează căutarea
                      </button>{" "}
                      {/*
                      <Link to="/listacautare">
                        <button
                          type="submit"
                        onClick={() => {
                          this.getDynamicList();
                        }}
                          className="btn btn-success btn-sm"
                        >
                          Afișează lista salvată
                        </button>
                      </Link>
                      */}
                    </div>
                    {/*
                    <div style={{ marginTop: "1%" }}>
                      <Link to="/anunturi/favorite">
                        <button
                          type="submit"
                          className="btn btn-success btn-sm"
                        >
                          Afișează anunţurile favorite
                        </button>
                      </Link>
                    </div>
                    */}
                  </div>
                )}
            </div>
            {/*
              <div className="col-xs-6 text-right">
                <div className="no-margin" style={{ paddingTop: "12px" }}></div>
              </div>
              */}
          </div>
          <div className="box-body">
            <div className="flex-container">
              {items.map((item) => (
                <Anunt
                  detalii={item}
                  key={item._id}
                  tip_anunt={this.state.tip_anunt}
                  tip_prop={this.state.tip_prop}
                  deleteFavorite={() => this.deleteFavorite(item._id)}
                />
              ))}
            </div>
          </div>
        </div>
        <PaginationExamplePagination
          callbackPage={this.callbackPage}
          page={this.state.page}
          pageChanged={this.state.pageChanged}
          filtersAppl={this.state.filtersAppl}
          callbackFilters={this.callbackFilters}
        />
      </div>
    );
  }
}

export default Lista_Anunturi;
