import React, { Component } from "react";
import loginService from "./servicelogin";
//import { ToastContainer } from "react-toastr";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import classnames from "classnames";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //username: "",
      email: "",
      password: "",
      user: null
    };
  }

  componentDidMount() {
    const bodyClasses = classnames("hold-transition", "login-page");

    document.getElementsByTagName("body")[0].className = bodyClasses;
    document.getElementById("root").className = "login-box";
  }

  componentWillUnmount() {
    const bodyClasses = classnames("skin-blue", "sidebar-mini");

    document.getElementsByTagName("body")[0].className = bodyClasses;
    document.getElementById("root").className = "wrapper";
  }

  handleLogin = async event => {
    event.preventDefault();
    //console.log("logging in with", this.state.username, this.state.password);
    const { email, password } = this.state;

    try {
      const user = await loginService.login({
        email,
        password
      });

      //const user = response.data;

      window.localStorage.setItem("loggedUser", JSON.stringify(user));
      const currentTime = new Date().getTime();
      window.localStorage.setItem("accepted", currentTime);

      this.setState(user);
      this.setState({
        email: "",
        password: ""
      });

      loginService.setToken(user.token);

      //console.log(user);

      this.props.history.push({
        pathname: "/",
        state: { loggedIn: true }
      });
    } catch (exception) {
      //console.log(exception.message);
      //console.log("Wrong credentials");
      /*
      setErrorMessage("Wrong credentials");
      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
      */

      //const error = response.error;
      //console.log(error);

      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 6000
      };
      toastr.clear();
      setTimeout(() => toastr.error(`Email sau parolă incorecte`), 300);

      /*
      this.props.history.push({
        pathname: "/",
        state: { loggedIn: false }
      });
      */
    }
  };

  render() {
    return (
      /*
      <div
        className="hold-transition login-page"
        //style={{ minHeight: 750, padding: "5%" }}
      >
      */
      //<div className="login-box">
      <div>
        <div className="login-logo">
          <a href="#">
            <strong>Treadstone</strong>
          </a>
        </div>
        <div className="login-box-body">
          <p className="login-box-msg">Sign in to start your session</p>
          <form onSubmit={this.handleLogin}>
            <div className="form-group has-feedback">
              Email
              <input
                type="text"
                value={this.state.email}
                name="Email"
                className="form-control"
                onChange={({ target }) =>
                  this.setState({ email: target.value })
                }
              />
            </div>
            <div className="form-group has-feedback">
              Password
              <input
                type="password"
                value={this.state.password}
                name="Password"
                className="form-control"
                onChange={({ target }) =>
                  this.setState({ password: target.value })
                }
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block btn-flat"
            >
              Login
            </button>
          </form>
        </div>
      </div>
      //</div>
    );
  }
}

export default Login;
