import React, { Component } from "react";
import loginService from "./servicelogin";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

class Afisare_Detalii extends Component {
  constructor(props) {
    super(props);
    this.state = {
      announce: this.props.anunt,
      isFavorite: false,
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };

    this.addFavoriteAnnounce = this.addFavoriteAnnounce.bind(this);
    this.deleteFavoriteAnnounce = this.deleteFavoriteAnnounce.bind(
      this
    );
  }

  componentDidMount() {
    //console.error("Announce", this.state.announce);

    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    if (tip_prop === "apartamente") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("sale/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rent/");
      }
    } else if (tip_prop === "case") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleHouse/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentHouse/");
      }
    } else if (tip_prop === "terenuri") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleLand/");
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentLand/");
      }
    }

    const userFavorites = this.state.announce.userFavorites;

    const loggedUserJSON = window.localStorage.getItem("loggedUser");
    let userId;

    if (loggedUserJSON) {
      //console.error("User ID", JSON.parse(loggedUserJSON).userid);

      userId = JSON.parse(loggedUserJSON).userid;
      this.setState({
        isFavorite: userFavorites.includes(userId),
      });
      //console.error("Is favorite", this.state.isFavorite);
    }
  }

  /*
  componentWillReceiveProps(nextProps) {
    //console.error("Next props", nextProps.tip);

    if (nextProps.tip !== this.state.tip) {
      const newTip = nextProps.tip;
      if (newTip === "vanzare") {
        loginService.setUrl("sale/");

        this.setState({
          tip: newTip,
        });
      } else if (newTip === "inchiriere") {
        loginService.setUrl("rent/");

        this.setState({
          tip: newTip,
        });
      }
    }
  }
  */

  addFavoriteAnnounce = async (event) => {
    const id = this.state.announce._id;

    const announceId = {
      announceId: id,
    };

    try {
      const response = await loginService.addFavoriteAnnounce(
        announceId
      );

      //console.error("Response for favorite", response);

      if (response.status === 200) {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () => toastr.success(`Anunțul a fost salvat cu succes`),
          300
        );

        this.setState({
          isFavorite: true,
        });
      } else {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () => toastr.error(`Anunțul nu a putut fi salvat`),
          300
        );
      }
    } catch (err) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(
        () => toastr.error(`Anunțul nu a putut fi salvat`),
        300
      );
    }
  };

  deleteFavoriteAnnounce = async (event) => {
    //console.log("Delete");
    const id = this.state.announce._id;

    const announceId = {
      announceId: id,
    };

    try {
      const response = await loginService.deleteFavoriteAnnounce(
        announceId
      );

      //console.error("Delete response", response);

      if (response.status === 200) {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () =>
            toastr.success(
              `Anunțul a fost șters cu succes din favorite`
            ),
          300
        );

        this.setState({
          isFavorite: false,
        });
      } else {
        toastr.options = {
          positionClass: "toast-top-center",
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(
          () =>
            toastr.error(`Anunțul nu a putut fi șters din favorite`),
          300
        );
      }
    } catch (err) {
      toastr.options = {
        positionClass: "toast-top-center",
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(
        () =>
          toastr.error(`Anunțul nu a putut fi șters din favorite`),
        300
      );
    }

    //window.location.reload();
  };

  render() {
    const anunt = this.state.announce;

    const loggedUserJSON = window.localStorage.getItem("loggedUser");

    const isFavorite = this.state.isFavorite;

    let isLand = false;
    if (this.state.tip_prop === "terenuri") {
      isLand = true;
    }

    return (
      <div className="col-xs-12 col-md-12 white-back">
        <div className="title">
          <p>{anunt.title}</p>
          {loggedUserJSON &&
            (JSON.parse(loggedUserJSON).features.includes(4) ||
              JSON.parse(loggedUserJSON).features.includes(0)) &&
            (isFavorite ? (
              <button
                type="submit"
                onClick={() => {
                  this.deleteFavoriteAnnounce();
                }}
                className="btn btn-danger btn-sm"
              >
                Șterge din favorite
              </button>
            ) : (
              <button
                type="submit"
                onClick={() => {
                  this.addFavoriteAnnounce();
                }}
                className="btn btn-success btn-sm"
              >
                Salvează la favorite
              </button>
            ))}
        </div>
        <div className="pretul">Preţ: {anunt.price.value} EUR</div>
        <div className="zona">Zona: {anunt.zone.extended}</div>

        <div className="col-xs-12 col-md-3">
          <div className="card col-xs-12 col-md-12 text-center">
            <div
              id={anunt._id}
              className="carousel slide"
              data-ride="carousel"
            >
              <ol className="carousel-indicators">
                <li
                  data-target={"#" + anunt._id}
                  data-slide-to="0"
                  className="active"
                ></li>
                <li
                  data-target={"#" + anunt._id}
                  data-slide-to="1"
                ></li>
                <li
                  data-target={"#" + anunt._id}
                  data-slide-to="2"
                ></li>
              </ol>
              <div className="carousel-inner">
                <div className="item active">
                  <img
                    src={anunt.images[0]}
                    style={{ width: "100%", minHeight: "13vw" }}
                  />
                </div>
                <div className="item">
                  <img
                    src={anunt.images[1]}
                    style={{ width: "100%", minHeight: "13vw" }}
                  />
                </div>
                <div className="item">
                  <img
                    src={anunt.images[2]}
                    style={{ width: "100%", minHeight: "13vw" }}
                  />
                </div>

                <div className="carousel-caption">
                  <p className="price">
                    <b>Price: {anunt.price.value}€</b>
                  </p>
                </div>

                <a
                  className="left carousel-control"
                  href={"#" + anunt._id}
                  data-slide="prev"
                >
                  <span className="glyphicon glyphicon-chevron-left"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="right carousel-control"
                  href={"#" + anunt._id}
                  data-slide="next"
                >
                  <span className="glyphicon glyphicon-chevron-right"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-9">
          <table style={{ margin: 0 }}>
            <tbody>
              <tr>
                <td className="desc_title">Descrierea anunţului.</td>
              </tr>
              <tr>
                <td id="descriere">{anunt.details}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div className="col-xs-12 col-md-12" id="cara">
            <p className="detail_title">Caracteristici</p>

            {anunt.characteristics.numberOfRooms ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Nr camere: </b>
                  <span>{anunt.characteristics.numberOfRooms}</span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.usableSurface ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Suprafaţa utilă: </b>
                  <span>{anunt.characteristics.usableSurface}</span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.constructedSurface ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Suprafaţa construită: </b>
                  <span>
                    {anunt.characteristics.constructedSurface}
                  </span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.constructionYear ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>An construcţie: </b>
                  <span>
                    {anunt.characteristics.constructionYear.min}
                  </span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.partition ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Compartimentare: </b>
                  <span>{anunt.characteristics.partition}</span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.comfort ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Confort: </b>
                  <span>{anunt.characteristics.comfort}</span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.floor ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Etaj: </b>
                  <span>{anunt.characteristics.floor}</span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.numberOfKitchens ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Nr bucătării: </b>
                  <span>
                    {anunt.characteristics.numberOfKitchens}
                  </span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.numberOfBathrooms ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Nr băi: </b>
                  <span>
                    {anunt.characteristics.numberOfBathrooms}
                  </span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.numberOfBalconies ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Nr balcoane: </b>
                  <span>
                    {anunt.characteristics.numberOfBalconies}
                  </span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.resistantStructure ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Structura rezistenţă: </b>
                  <span>
                    {anunt.characteristics.resistantStructure}
                  </span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {anunt.characteristics.immobileType ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Tip imobil: </b>
                  <span>{anunt.characteristics.immobileType}</span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}

            {/*
            {anunt.characteristics.heightRegime ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Regim înălţime: </b>
                  <span>{anunt.characteristics.heightRegime}</span>
                </p>
              </div>
            ) : (
              <div></div>
            )}
            */}

            {anunt.characteristics.utilities ? (
              <div className="col-xs-12 col-md-4">
                <p className="caract_insert">
                  <b>Utilități: </b>
                  <span>{anunt.characteristics.utilities}</span>
                </p>
              </div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "0",
                  margin: "0",
                }}
              ></div>
            )}
          </div>

          {/* custom to lands */}
          {isLand && anunt.characteristics.terrainSurface ? (
            <div className="col-xs-12 col-md-4">
              <p className="caract_insert">
                <b>Suprafață teren: </b>
                <span>{anunt.characteristics.terrainSurface}</span>
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "inline-block",
                width: "0",
                margin: "0",
              }}
            ></div>
          )}

          {isLand && anunt.characteristics.streetFront ? (
            <div className="col-xs-12 col-md-4">
              <p className="caract_insert">
                <b>Front stradal: </b>
                <span>{anunt.characteristics.streetFront}</span>
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "inline-block",
                width: "0",
                margin: "0",
              }}
            ></div>
          )}

          {isLand && anunt.characteristics.numberOfFronts ? (
            <div className="col-xs-12 col-md-4">
              <p className="caract_insert">
                <b>Nr fronturi: </b>
                <span>{anunt.characteristics.numberOfFronts}</span>
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "inline-block",
                width: "0",
                margin: "0",
              }}
            ></div>
          )}

          {isLand && anunt.characteristics.type ? (
            <div className="col-xs-12 col-md-4">
              <p className="caract_insert">
                <b>Tip teren: </b>
                <span>{anunt.characteristics.type}</span>
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "inline-block",
                width: "0",
                margin: "0",
              }}
            ></div>
          )}

          {isLand && anunt.characteristics.classification ? (
            <div className="col-xs-12 col-md-4">
              <p className="caract_insert">
                <b>Clasificare teren: </b>
                <span>{anunt.characteristics.classification}</span>
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "inline-block",
                width: "0",
                margin: "0",
              }}
            ></div>
          )}

          {isLand && anunt.characteristics.constructionOnSite !== undefined ? (
            <div className="col-xs-12 col-md-4">
              <p className="caract_insert">
                <b>Construcție pe teren: </b>
                <span>
                  {anunt.characteristics.constructionOnSite === true
                    ? "Da"
                    : "Nu"}
                </span>
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "inline-block",
                width: "0",
                margin: "0",
              }}
            ></div>
          )}
        </div>

        <div className="col-xs-12 col-md-12 link">
          <p>
            <b>Link către anunțul original: </b>
            <a href={anunt.link} target="_blank">
              {anunt.link}
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default Afisare_Detalii;
