import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Content from "./components/Content";
import Login from "./components/Login";
import Logout from "./components/Logout";

import Footer from "./components/Footer";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/*<Route exact path="/" component={Content} />*/}
          <Route exact path="/logout" component={Logout} />
          <Route
            path="/"
            component={Content}
            //render={props => <Content {...props} state={this.state} />}
          />
        </Switch>

        {/*
        <Header />
        <Sidebar />

        <Content />

         <Footer />
        */}

        {/*
        <Switch>
          <Route exact path="/" component={ Lista_Anunturi } />
          <Route path="/anunturi" component={ Anunturi } />
          <Route path="/detalii" component={ Detalii } />
          <Route path="/harta" component={ Harta } />
          <Route component={ Default } />
        </Switch>
        */}
      </React.Fragment>
    );
  }
}

export default App;
