import React, { Component } from "react";
import axios from "axios";
import L from "leaflet";
import "leaflet.markercluster";
import {
  imobiliarero1,
  imobiliarero2,
  imobiliarero3,
  imobiliarero4,
  olxro1,
  olxro2,
  olxro3,
  olxro4,
  homezzro1,
  homezzro2,
  homezzro3,
  homezzro4,
  questionmark,
  water,
  technician,
  electric,
  default1,
  default2,
  default3,
  default4,
} from "../icons_leaf";
import DropdownMenu from "./DropdownMenu";
import _ from "underscore";
import loginService from "./servicelogin";
import { baseUrl } from "../config";
//import ReactLeafletSearch from "react-leaflet-search";
import {
  GeoSearchControl,
  OpenStreetMapProvider,
  EsriProvider,
} from "leaflet-geosearch";
import "../../node_modules/leaflet-geosearch/assets/css/leaflet.css";
import "../customleaflet.css";

class Harta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        lat: 44.439663,
        lon: 26.096306,
        numberOfRooms: [],
        floor: [],
        usableSurface: {
          min: 10,
          max: 200,
        },
        price: {
          min: 20000,
          max: 100000,
        },
      },
      tip_anunt: this.props.tip_anunt,
      tip_prop: this.props.tip_prop,
    };
  }

  callbackNrRooms = (values) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        numberOfRooms: values
          ? values.map((item) => parseInt(item.value))
          : [],
      },
    }));
  };

  callbackFloor = (values) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        floor: values
          ? values.map((item) => parseInt(item.value))
          : [],
      },
    }));
  };

  callbackUsableSurface = (value) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        usableSurface: value,
      },
    }));
  };

  callbackPrice = (value) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        price: value,
      },
    }));
  };

  componentDidMount() {
    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    if (tip_prop === "apartamente") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("sale/");

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 20000,
              max: 100000,
            },
          },
        }));
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rent/");

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 100,
              max: 2000,
            },
          },
        }));
      }
    } else if (tip_prop === "case") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleHouse/");

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 20000,
              max: 100000,
            },
          },
        }));
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentHouse/");

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 100,
              max: 2000,
            },
          },
        }));
      }
    } else if (tip_prop === "terenuri") {
      if (tip_anunt === "vanzare") {
        loginService.setUrl("saleLand/");

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 20000,
              max: 100000,
            },
          },
        }));
      } else if (tip_anunt === "inchiriere") {
        loginService.setUrl("rentLand/");

        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            price: {
              min: 100,
              max: 2000,
            },
          },
        }));
      }
    }

    const { lat, lon } = this.state.filters;

    const provider = new OpenStreetMapProvider({
      params: { countrycodes: ["ro", "br"] },
    });
    //const provider = new EsriProvider();

    const searchControl = new GeoSearchControl({
      provider: provider,
      //style: 'bar',
      showMarker: false,
      showPopup: false,
      autoClose: true,
      keepResult: true,
      searchLabel: "Introduceti adresa",
      position: "topleft",
    });

    this.map = L.map("map", {
      center: [lat, lon],
      zoom: 16,
      layers: [
        L.tileLayer(
          "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        ),
      ],
    });

    this.map.addControl(searchControl);

    searchControl.getContainer().onclick = (e) => {
      e.stopPropagation();
    };

    this.map.on("geosearch/showlocation", (e) => {
      // CLICK IN SEARCHBAR
      const lat = e.location.y;
      const lon = e.location.x;

      //console.log("Loading announces from ", lat, lon);

      const prevLat = this.state.filters.lat;
      const prevLon = this.state.filters.lon;

      //console.log(lat, lon, prevLat, prevLon);

      const dist = Math.sqrt(
        Math.pow(lat - prevLat, 2) + Math.pow(lon - prevLon, 2)
      );

      //console.log(dist);

      if (dist >= 0.015) {
        //console.log("Setting new state");
        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            lat: parseFloat(lat),
            lon: parseFloat(lon),
          },
        }));
      }
    });

    //const searchComponent = (props) => <ReactLeafletSearch position="topleft" />;
    //this.map.addHandler(searchComponent)
    //console.log(map);

    // this.marker = L.marker(this.props.markerPosition).addTo(this.map);
    this.extraMarkers = L.markerClusterGroup({
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: 1,
    }).addTo(this.map);

    //ADAUGARE TEHNICIENI
    axios
      .get(baseUrl + "api/technicians")
      .then((res) =>
        res.data.map((t) =>
          L.marker([t.latitude, t.longitude], {
            icon:
              t.services[0] === "type1"
                ? electric
                : t.services[0] === "type2"
                ? water
                : technician,
          })
            .bindPopup(
              `<b>Nume: </b>${t.lastname}<br> <b>Numar de telefon: </b> ${t.telephone}<br> <b>Email: </b> ${t.email}`
            )
            .addTo(this.extraMarkers)
        )
      )
      .catch((e) => console.log(e));
    // [44.439663, 26.096306] CENTRU
    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: electric }
    )
      .bindPopup(
        "<b>Nume: </b> Marian Andrei<br> <b>Email: </b> marian.andrei@gmail.com<br> <b>Numar de telefon: </b> 0788 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: electric }
    )
      .bindPopup(
        "<b>Nume: </b> Marius Ionescu<br> <b>Email: </b> marius.ionescu@gmail.com<br> <b>Numar de telefon: </b> 0765 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: electric }
    )
      .bindPopup(
        "<b>Nume: </b> Dragos Ion<br> <b>Email: </b> dragos.ion@gmail.com<br> <b>Numar de telefon: </b> 0779 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: electric }
    )
      .bindPopup(
        "<b>Nume: </b> Darius Dragomir<br> <b>Email: </b> darius.dragomir@gmail.com<br> <b>Numar de telefon: </b> 0786 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: electric }
    )
      .bindPopup(
        "<b>Nume: </b> Ion Popescu<br> <b>Email: </b> ion.popescu@gmail.com<br> <b>Numar de telefon: </b> 0745 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: water }
    )
      .bindPopup(
        "<b>Nume: </b> Razvan Popescu<br> <b>Email: </b> razvan.popescu@gmail.com<br> <b>Numar de telefon: </b> 0789 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: water }
    )
      .bindPopup(
        "<b>Nume: </b> Paul Ionescu<br> <b>Email: </b> paul.ionescu@gmail.com<br> <b>Numar de telefon: </b> 0767 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: water }
    )
      .bindPopup(
        "<b>Nume: </b> David Cristian<br> <b>Email: </b> david.cristian@gmail.com<br> <b>Numar de telefon: </b> 0753 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: water }
    )
      .bindPopup(
        "<b>Nume: </b> Ion Popescu<br> <b>Email: </b> ion.popescu@gmail.com<br> <b>Numar de telefon: </b> 0755 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: water }
    )
      .bindPopup(
        "<b>Nume: </b> Andrei Marinescu<br> <b>Email: </b> andrei.marinescu@gmail.com<br> <b>Numar de telefon: </b> 0788 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: technician }
    )
      .bindPopup(
        "<b>Nume: </b> Marian Dinescu<br> <b>Email: </b> marian.dinescu@gmail.com<br> <b>Numar de telefon: </b> 0792 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: technician }
    )
      .bindPopup(
        "<b>Nume: </b> Alexandru Marin<br> <b>Email: </b> alexandru.marin@gmail.com<br> <b>Numar de telefon: </b> 0756 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: technician }
    )
      .bindPopup(
        "<b>Nume: </b> George Preda<br> <b>Email: </b> george.preda@gmail.com<br> <b>Numar de telefon: </b> 0745 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: technician }
    )
      .bindPopup(
        "<b>Nume: </b> Ion Georgescu<br> <b>Email: </b> ion.georgescu@gmail.com<br> <b>Numar de telefon: </b> 0778 123 456"
      )
      .addTo(this.extraMarkers);

    L.marker(
      [
        44.40322757785135 + Math.random() / 10,
        26.036306 + Math.random() / 10,
      ],
      { icon: technician }
    )
      .bindPopup(
        "<b>Nume: </b> Liviu Alexe<br> <b>Email: </b> liviu.alexe@gmail.com<br> <b>Numar de telefon: </b> 0776 123 456"
      )
      .addTo(this.extraMarkers);

    this.markers = L.markerClusterGroup({
      spiderfyOnMaxZoom: true,
      showCoverageOnHover: true,
      zoomToBoundsOnClick: true,
      maxClusterRadius: function (zoom) {
        if (zoom === 18) return 0;
        return 80;
      },
    }).addTo(this.map);
    // this.layer = L.layerGroup().addTo(this.map);
    //this.updateMarkers(this.props.markersData);
    const filters = this.state.filters;
    this.loadMarkers(filters);
    //this.loadMarkers(44.439663, 26.096306);

    this.map.on("click", (e) => {
      const lat = e.latlng.lat;
      const lon = e.latlng.lng;

      const prevLat = this.state.filters.lat;
      const prevLon = this.state.filters.lon;

      //console.log(lat, lon, prevLat, prevLon);

      const dist = Math.sqrt(
        Math.pow(lat - prevLat, 2) + Math.pow(lon - prevLon, 2)
      );

      //console.log(dist);

      if (dist >= 0.015) {
        this.setState((prevState) => ({
          filters: {
            ...prevState.filters,
            lat: parseFloat(lat),
            lon: parseFloat(lon),
          },
        }));
      }
    });

    //    this.handleClick = this.handleClick.bind(this);
  }
  /*
handleClick(e) {
  this.markers.clearLayers();
  this.loadMarkers(e.latlng.lat, e.latlng.lng);
}*/

  static getDerivedStateFromProps(nextProps, prevState) {
    console.error(
      "Next props",
      nextProps.tip_anunt,
      nextProps.tip_prop
    );

    if (
      nextProps.tip_anunt !== prevState.tip_anunt ||
      nextProps.tip_prop !== prevState.tip_prop
    ) {
      const new_tip_anunt = nextProps.tip_anunt;
      const new_tip_prop = nextProps.tip_prop;

      if (new_tip_prop === "apartamente") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("sale/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              lat: 44.439663,
              lon: 26.096306,
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 20000,
                max: 100000,
              },
            },
          };
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rent/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              lat: 44.439663,
              lon: 26.096306,
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 100,
                max: 2000,
              },
            },
          };
        }
      } else if (new_tip_prop === "case") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleHouse/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              lat: 44.439663,
              lon: 26.096306,
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 20000,
                max: 100000,
              },
            },
          };
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentHouse/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              lat: 44.439663,
              lon: 26.096306,
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 100,
                max: 2000,
              },
            },
          };
        }
      } else if (new_tip_prop === "terenuri") {
        if (new_tip_anunt === "vanzare") {
          loginService.setUrl("saleLand/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 20000,
                max: 100000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        } else if (new_tip_anunt === "inchiriere") {
          loginService.setUrl("rentLand/");

          console.log(loginService.getUrl());

          return {
            tip_anunt: new_tip_anunt,
            tip_prop: new_tip_prop,
            filters: {
              numberOfRooms: [],
              floor: [],
              usableSurface: {
                min: 10,
                max: 200,
              },
              price: {
                min: 100,
                max: 2000,
              },
              site: null,
              textSearch: null,
            },
            page: 1,
            search: "",
          };
        }
      } else return null;
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   //console.error("Next props", nextProps.tip);

  //   if (nextProps.tip !== this.state.tip) {
  //     const newTip = nextProps.tip;
  //     if (newTip === "vanzare") {
  //       loginService.setUrl("sale/");

  //       this.setState({
  //         tip: newTip,
  //         filters: {
  //           lat: 44.439663,
  //           lon: 26.096306,
  //           numberOfRooms: [],
  //           floor: [],
  //           usableSurface: {
  //             min: 10,
  //             max: 200,
  //           },
  //           price: {
  //             min: 20000,
  //             max: 100000,
  //           },
  //         },
  //       });
  //     } else if (newTip === "inchiriere") {
  //       loginService.setUrl("rent/");

  //       this.setState({
  //         tip: newTip,
  //         filters: {
  //           lat: 44.439663,
  //           lon: 26.096306,
  //           numberOfRooms: [],
  //           floor: [],
  //           usableSurface: {
  //             min: 10,
  //             max: 200,
  //           },
  //           price: {
  //             min: 100,
  //             max: 2000,
  //           },
  //         },
  //       });
  //     }

  //     const filters = this.state.filters;

  //     this.loadMarkers(filters);
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    //console.log("Map updated");

    const filters = this.state.filters;
    const prevFilters = prevState.filters;

    //console.log(filters);
    //console.log(prevState);
    //console.log(prevFilters);

    //console.log(!_.isEqual(filters, prevFilters));

    if (
      !_.isEqual(filters, prevFilters) ||
      this.state.tip_anunt !== prevState.tip_anunt ||
      this.state.tip_prop !== prevState.tip_prop
    ) {
      console.log("adica intru si aici");
      this.markers.clearLayers();
      this.loadMarkers(filters);
    }
  }

  getIcon = function (site, rooms) {
    // console.log(site, rooms);
    if (site === "imobiliarero") {
      if (rooms === 1) {
        return imobiliarero1;
      } else if (rooms === 2) {
        return imobiliarero2;
      } else if (rooms === 3) {
        return imobiliarero3;
      } else {
        return imobiliarero4;
      }
    } else if (site === "olxro") {
      if (rooms === 1) {
        return olxro1;
      } else if (rooms === 2) {
        return olxro2;
      } else if (rooms === 3) {
        return olxro3;
      } else {
        return olxro4;
      }
    } else if (site === "homezzro") {
      if (rooms === 1) {
        return homezzro1;
      } else if (rooms === 2) {
        return homezzro2;
      } else if (rooms === 3) {
        return homezzro3;
      } else {
        return homezzro4;
      }
    } else if (site === "imovelwebbr") {
      if (rooms === 1) {
        return olxro1;
      } else if (rooms === 2) {
        return olxro2;
      } else if (rooms === 3) {
        return olxro3;
      } else {
        return olxro4;
      }
    } else if (rooms >= 1 && rooms < 2) {
      return default1;
    } else if (rooms >= 2 && rooms < 3) {
      return default2;
    } else if (rooms >= 3 && rooms < 4) {
      return default3;
    } else if (rooms >= 4) {
      return default4;
    }
    return default4;
  };

  loadMarkers = async (filters) => {
    const data = await loginService.getMap(filters);

    //console.log("Data:", data);

    for (let announce of data) {
      if (announce.images.length === 0) {
        continue;
      }
      L.marker(
        [
          announce.location.coordinates[1],
          announce.location.coordinates[0],
        ],
        {
          icon: this.getIcon(
            announce.site,
            announce.characteristics?.numberOfRooms
          ),
        }
      )
        .bindPopup(
          `
            <b>
              <div style="max-width: 150px">
                <a href="${announce.link}" target="_blank">${announce.title}</a>
              </div>
            </b>
            <img src="${announce.images[0]}" style="height: 150px; width: 150px" />
            <br />
            <div style="font-size: 20px">${announce.price.value} ${announce.price.currency}</div>
          `
        )
        .addTo(this.markers);
    }

    this.markers.addTo(this.map);
  };

  /*
  loadMarkers = async filters => {
    const config = {
      headers: { Authorization: loginService.token }
    };
    console.error(config);
    console.error(loginService.getToken());

    let { data } = await axios.post(
      "http://10.10.15.151:3000/app/getAnnouncesWithGeo",
      filters,
      config
    );

    for (let announce of data) {
      if (announce.images.length === 0) {
        continue;
      }
      L.marker(
        [announce.location.coordinates[1], announce.location.coordinates[0]],
        {
          icon: this.getIcon(
            announce.site,
            announce.characteristics.numberOfRooms
          )
        }
      )
        .bindPopup(
          `
            <b>
              <div style="max-width: 150px">
                <a href="${announce.link}" target="_blank">${announce.title}</a>
              </div>
            </b>
            <img src="${announce.images[0]}" style="height: 150px; width: 150px" />
            <br />
            <div style="font-size: 20px">${announce.price.value} ${announce.price.currency}</div>
          `
        )
        .addTo(this.markers);
    }

    this.markers.addTo(this.map);
  };
  */

  render() {
    const tip_anunt = this.state.tip_anunt;
    const tip_prop = this.state.tip_prop;

    let initialPrice;
    if (tip_anunt === "vanzare") {
      initialPrice = {
        min: 20000,
        max: 100000,
      };
    } else if (tip_anunt === "inchiriere") {
      initialPrice = {
        min: 100,
        max: 2000,
      };
    }

    return (
      <div className="white-back">
        <DropdownMenu
          callbackNrRooms={this.callbackNrRooms}
          savedNrRooms={this.state.filters.numberOfRooms}
          callbackFloor={this.callbackFloor}
          savedFloor={this.state.filters.floor}
          callbackUsableSurface={this.callbackUsableSurface}
          savedUsableSurface={this.state.filters.usableSurface}
          callbackPrice={this.callbackPrice}
          savedPrice={this.state.filters.price}
          initialPrice={initialPrice}
          tip_anunt={this.state.tip_anunt}
          tip_prop={this.state.tip_prop}
        />

        <div
          id="map"
          style={{ height: "80vh", width: "100vw", zIndex: "0" }}
        ></div>
      </div>
    );
  }
}

export default Harta;
