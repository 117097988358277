import React, { Component } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";

class Range extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: { min: 10, max: 200 }
    };

    this.props.callback(this.state.value);
  }

  componentWillReceiveProps({ savedUsableSurface }) {
    this.setState({
      value: savedUsableSurface
    });
  }

  handleChange = value => {
    this.setState({ value });
    this.props.callback(value);
    //console.log(this.state.value.min, this.state.value.max);
  };

  render() {
    //const format = { __html: "m<sup>2</sup>" };
    return (
      <form style={{ margin: "0 auto", padding: "20px 15px 20px" }}>
        <InputRange
          minValue={0}
          maxValue={500}
          formatLabel={value => `${value}`}
          step={5}
          value={this.state.value}
          onChange={value => this.setState({ value })}
          onChangeComplete={this.handleChange}
        />
      </form>
    );
  }
}

export default Range;
