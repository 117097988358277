import axios from "axios";
import { baseUrl } from "../config";

//"http://10.10.15.151:3000/api/technicians"
//const baseUrl = "http://10.10.15.151:3000/api/login";
//const baseUrl = testIP;

let token = null;

const setToken = (newToken) => {
  token = `bearer ${newToken}`;

  console.log(token);
};

const getToken = () => token;

let url;

const setUrl = (urlFromComp) => {
  url = urlFromComp;
};

const getUrl = () => url;

/*
const getAll = () => {
  const request = axios.get(baseUrl);

  return request.then(response => response.data);
};

const create = async newObject => {
  const config = {
    headers: { Authorization: token }
  };

  const response = await axios.post(baseUrl, newObject, config);
  return response.data;
};

const update = (id, newObject) => {
  const request = axios.put(`${baseUrl} /${id}`, newObject);

  return request.then(response => response.data);
};
*/

const login = async (credentials) => {
  const response = await axios.post(baseUrl + "api/loginv2", credentials);

  //console.log(response);
  return response.data;
};

const getMap = async (filters) => {
  const config = {
    headers: { Authorization: getToken() },
  };
  //console.error(JSON.parse(token));
  console.error(config);
  //console.error(loginService.getToken());

  const { data } = await axios.post(
    baseUrl + "app/" + url + "getAnnouncesWithGeo",

    filters,
    config
  );

  return data;
};

const getMapU = async (filters) => {
  const config = {
    headers: { Authorization: getToken() },
  };
  //console.error(JSON.parse(token));
  console.error(config);
  //console.error(loginService.getToken());

  const { data } = await axios.post(
      baseUrl + "app/" + url + "getAnnouncesWithGeo",

      filters,
      config
  );

  return data;
};

const getAnnounces = async (filters) => {
  const config = {
    headers: { Authorization: getToken() },
  };

  console.log(baseUrl + "app/" + url + "announcesv2");

  const { data } = await axios.post(
    baseUrl + "app/" + url + "announcesv2",
    filters,
    config
  );

  console.log(data);

  return data;
};

const getTechnicians = async () => {
  const response = await axios.get(baseUrl + "api/technicians");
  return response.data;
};

const addTechnician = async (newObject) => {
  /*
  const config = {
    headers: { Authorization: getToken() }
  };
  */

  //const response = await axios.post(baseUrl + "api/technicians", data, config);
  const response = await axios.post(baseUrl + "api/technicians", newObject);
  return response.data;
};

const updateTechnician = async (id, newObject) => {
  const response = await axios.put(
    `${baseUrl + "api/technicians"}/${id}`,
    newObject
  );
  return response.data;
};

const deleteTechnician = async (id) => {
  const response = await axios.delete(`${baseUrl + "api/technicians"}/${id}`);
  return response.data;
};

const addDynamicList = async (query) => {
  const config = {
    headers: { Authorization: getToken() },
  };

  const response = await axios.post(
    baseUrl + "api/" + url + "lists/dynamic",

    query,
    config
  );

  return response;
};

const getDynamicList = async () => {
  const config = {
    headers: { Authorization: getToken() },
  };

  const { data } = await axios.get(
    baseUrl + "api/" + url + "lists/dynamic",
    config
  );

  return data;
};

const getListById = async (listId) => {
  const config = {
    headers: { Authorization: getToken() },
  };

  const { data } = await axios.post(
    baseUrl + "api/" + url + "lists/dynamic/one",
    listId,
    config
  );

  return data;
};

const deleteDynamicList = async (listId) => {
  const config = {
    headers: { Authorization: getToken() },
  };

  const response = await axios.post(
    baseUrl + "api/" + url + "lists/dynamic/delete",

    listId,
    config
  );

  return response;
};

const addFavoriteAnnounce = async (announceId) => {
  const config = {
    headers: { Authorization: getToken() },
  };

  const response = await axios.post(
    baseUrl + "api/" + url + "lists/favorite",

    announceId,
    config
  );

  return response;
};

const getFavoriteAnnounce = async () => {
  const config = {
    headers: { Authorization: getToken() },
  };

  const { data } = await axios.get(
    baseUrl + "api/" + url + "lists/favorite",
    config
  );

  return data;
};

const deleteFavoriteAnnounce = async (announceId) => {
  const config = {
    headers: { Authorization: getToken() },
  };

  const response = await axios.post(
    baseUrl + "api/" + url + "lists/favorite/delete",
    announceId,
    config
  );

  return response;
};

//export default { login };
export default {
  login,
  setToken,
  getToken,
  setUrl,
  getUrl,
  getMap,
  getMapU,
  getAnnounces,
  getTechnicians,
  addTechnician,
  updateTechnician,
  deleteTechnician,
  addDynamicList,
  getDynamicList,
  deleteDynamicList,
  getListById,
  addFavoriteAnnounce,
  getFavoriteAnnounce,
  deleteFavoriteAnnounce,
};

//export default { getAll, create, update, setToken };
